import {
    UserManager,
    WebStorageStateStore,
    InMemoryWebStorage,
    Log,
} from 'oidc-client';
import { mutateSigninSilent } from './useAuth';
import {
    REDIRECT,
    LOGOUT_REDIRECT,
    BEFORE_LOGOUT_URL,
} from '../constants/routes';
import { source, grApiInstance } from '../fetchers/utils';

const {
    REACT_APP_CLIENT_ID: CLIENT_ID,
    REACT_APP_AUTH_PROVIDER: AUTH_PROVIDER,
    REACT_APP_OPEN_AUTH_LOG: OPEN_AUTH_LOG,
    REACT_APP_GO_LOGIN_TYPE: GO_LOGIN_TYPE
} = process.env;

// Auth API
const AUTH_OAUTH2_URL = `${ AUTH_PROVIDER }/oauth2`;
const AUTHORIZATION_ENDPOINT = `${ AUTH_OAUTH2_URL }/auth`;
const TOKEN_ENDPOINT = `${ AUTH_OAUTH2_URL }/token`;
const LOGOUT_REDIRECT_ENDPOINT = `${ AUTH_OAUTH2_URL }/sessions/logout`;
const USERINFO_ENDPOINT = `${ AUTH_PROVIDER }/userinfo`;
const ISSUER = `${ AUTH_PROVIDER }/`;

const ORIGIN = new URL(window.location.href).origin;
const REDIRECT_URI = `${ ORIGIN }${ REDIRECT }`;
const LOGOUT_REDIRECT_URI = `${ ORIGIN }${ LOGOUT_REDIRECT }`;

if (OPEN_AUTH_LOG === 'true') {
    Log.logger = console;
    Log.level = Log.DEBUG;
}

// unit is minute
const accessTokenExpiringNotificationTime = 30;

const userManager = new UserManager({
    client_id: CLIENT_ID,
    authority: AUTH_PROVIDER,
    response_type: 'code',
    scope: 'openid offline',
    redirect_uri: REDIRECT_URI,
    post_logout_redirect_uri: LOGOUT_REDIRECT_URI,
    metadata: {
        authorization_endpoint: AUTHORIZATION_ENDPOINT,
        token_endpoint: TOKEN_ENDPOINT,
        userinfo_endpoint: USERINFO_ENDPOINT,
        end_session_endpoint: LOGOUT_REDIRECT_ENDPOINT,
        issuer: ISSUER,
    },
    extraQueryParams: {
        go_login_type: GO_LOGIN_TYPE
    },
    userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    monitorSession: false,
});

export function getUser() {
    return userManager.getUser();
}

export async function tokenExpired() {
    const user = await getUser();
    if (!user) {
        return true;
    }
    return user.expires_in < accessTokenExpiringNotificationTime * 60;
}

export async function getAccessToken() {
    let user = await getUser();

    if (await tokenExpired()) {
        user = await mutateSigninSilent();
    }

    const { access_token, token_type } = user;

    return { access_token, token_type };
}

export async function signinRedirect(params) {
    await userManager.signinRedirect(params);
}

export async function signinCallback(url) {
    const user = await userManager.signinCallback(url);
    await mutateSigninSilent(user, false);
}

export async function signinSilent() {
    try {
        const user = await userManager.signinSilent();
        const { error } = user ?? {};
        if (error) {
            throw user;
        }
        delete grApiInstance.defaults['cancelToken'];
        return user;
    }
    catch (error) {
        await userManager.removeUser();
        source.cancel();
        throw error;
    }
}

export async function signoutRedirect(pathname) {
    sessionStorage.setItem(BEFORE_LOGOUT_URL, pathname);
    await userManager.signoutRedirect();
}

export async function signoutCallback(url, keepOpen = false) {
    await userManager.signoutCallback(url, keepOpen);
}

export async function removeUser() {
    await userManager.removeUser();
}

export function clearStaleState() {
    userManager.clearStaleState();
}
