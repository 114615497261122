import { useMemo } from 'react';
import { IconButton, HStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { DATE_TIME_FORMAT } from '../../constants/common';
import { ReactComponent as RefreshSVG } from '../../assets/refresh.svg';
import Text from '../../components/Text/Text';

function Refresh({ onClick, lastUploadTime, ...restProps }) {
    const lastUploadTimeFormated = useMemo(() => (
        lastUploadTime ? format(new Date(lastUploadTime), DATE_TIME_FORMAT) : undefined
    ), [lastUploadTime]);

    return (
        <HStack spacing="14px" { ...restProps }>
            { lastUploadTimeFormated && (
                <Text
                    variant="value"
                    value={ `更新時間: ${ lastUploadTimeFormated }` }
                    display={ ['none', 'inherit'] }
                    fontSize={ ['14px', '14px', 'inherit'] }
                    textAlign="right"
                />
            ) }
            <IconButton
                onClick={ onClick }
                variant="icon-button"
                isRound
                icon={ <RefreshSVG width="16px" height="16px" /> }
            />
        </HStack>
    );
}

Refresh.propTypes = {
    onClick: PropTypes.func.isRequired,
    lastUploadTime: PropTypes.string,
};

Refresh.defaultProps = {
    lastUploadTime: undefined,
};

export default Refresh;
