import { useEffect, useState, useContext } from 'react';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Box,
    Avatar,
    Flex,
    Button,
    Heading,
    useDisclosure,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import ImgAvatar from '../../assets/img-avatar@3x.png';
import { signoutRedirect, getUser } from '../../auth/userManager';
import { ProfileContext } from '../../pages/ScooterManagement';

export default function ProfilePopover() {
    const [grUserName, setGrUserName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const { isOpen, onClose, onToggle } = useDisclosure();
    const userProfile = useContext(ProfileContext);
    const { userName, picture } = userProfile;

    useEffect(() => {
        async function getUserName() {
            const user = await getUser();
            let displayName = userName;
            if (user) {
                const { profile } = user;
                const { name } = profile;
                displayName = name;
            }
            setGrUserName(displayName);
        }
        getUserName();
    }, [userName]);

    const handleLogout = () => {
        setIsLoading(true);
        signoutRedirect(history.location.pathname);
    };

    return (
        <Box>
            <Popover
                id="profile"
                isOpen={ isOpen }
                onClose={ onClose }
                placement="bottom-end"
                offset={ [0, 38] }
                lazyBehavior="keepMounted"
                isLazy
            >
                <PopoverTrigger>
                    <Avatar
                        as="button"
                        width="36px"
                        height="36px"
                        src={ picture ?? ImgAvatar }
                        cursor="pointer"
                        backgroundColor="#5d5d5d7a"
                        _focus={ { outline: '0' } }
                        onClick={ onToggle }
                    />
                </PopoverTrigger>
                <PopoverContent
                    paddingY="32px"
                    paddingX="40px"
                    border="none"
                    borderRadius="20px"
                    boxShadow="0 0 15px 0 rgba(0, 0, 0, .1)"
                    _focus={ { outline: '0' } }
                >
                    <PopoverBody padding="0">
                        <Flex direction="column" alignItems="center" color="#3a3f52">
                            <Avatar
                                width="64px"
                                height="64px"
                                src={ picture ?? ImgAvatar }
                                marginBottom="16px"
                                backgroundColor="#5d5d5d7a"
                                _focus={ { outline: '0' } }
                            />
                            <Heading
                                isTruncated
                                maxWidth="100%"
                                fontSize="20px"
                                lineHeight="23px"
                                marginBottom="28px"
                            >
                                { grUserName }
                            </Heading>
                            <Button
                                colorScheme="bluish-grey"
                                paddingX="32px"
                                paddingY="13px"
                                height="48px"
                                onClick={ handleLogout }
                                isLoading={ isLoading }
                            >
                                登出
                            </Button>
                        </Flex>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Box>
    );
}
