import { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { VStack, Button, chakra } from '@chakra-ui/react';
import { AUTHED, useAuthContext, } from '../../auth/useAuth';
import { signinRedirect, clearStaleState } from '../../auth/userManager';
import LoginContainer from './LoginContainer';
import { BEFORE_LOGOUT_URL, ROOT } from '../../constants/routes';
import { ReactComponent as GovTitleSVG } from '../../assets/img-gr-title.svg';

const ChakraGovTitleSVG = chakra(GovTitleSVG);

export default function Login() {
    const [redirectTo, setRedirectTo] = useState();
    const { authStatus } = useAuthContext();

    const login = async () => {
        await signinRedirect();
    };

    useEffect(() => {
        clearStaleState();
    }, []);

    useEffect(() => {
        if (authStatus === AUTHED) {
            const beforeUrl = sessionStorage.getItem(BEFORE_LOGOUT_URL);
            sessionStorage.removeItem(BEFORE_LOGOUT_URL);
            setRedirectTo(beforeUrl ?? ROOT);
        }
    }, [authStatus]);

    return (
        <LoginContainer>
            <VStack spacing={ ['40px', '80px'] }>
                <ChakraGovTitleSVG transform={ ['scale(0.6)', 'initial'] } />
                <Button
                    variant="primary"
                    minWidth="240px"
                    height="48px"
                    padding="12px 32px"
                    lineHeight="1.5"
                    onClick={ login }
                >
                    登入
                </Button>
                { redirectTo && (
                    <Redirect to={ redirectTo } />
                ) }
            </VStack>
        </LoginContainer>
    );
}
