import React from 'react';
import { calculateScooter } from '../helpers/map';

const MapContext = React.createContext();

function mapReducer(state, action) {
    const { list, filterTown } = action.data;
    return { mergeScooterList: calculateScooter(list, filterTown) };
};

function MapProvider({ children }) {
    const [state, dispatch] = React.useReducer(mapReducer, { mergeScooterList: [] });
    const value = { state, dispatch };
    return (
        <MapContext.Provider value={ value }>
            { children }
        </MapContext.Provider>
    );
}

function useMap() {
    const context = React.useContext(MapContext);
    if (context === undefined) {
        throw new Error('useMap must be used within a MapProvider');
    }
    return context;
}

export { MapProvider, useMap };
