import { transparentize } from '@chakra-ui/theme-tools';

const Switch = {
    variants: {
        goshare({ theme }) {
            return {
                track: {
                    boxSizing: 'border-box',
                    width: '70px',
                    height: '40px',
                    padding: '8px',
                    backgroundColor: transparentize('#737d82', .1)(theme),
                    _checked: {
                        backgroundColor: transparentize('deep-sky-blue', .1)(theme),
                    },
                    _focus: {
                        boxShadow: 'initial',
                    }
                },
                thumb: {
                    width: '24px',
                    height: '24px',
                    transition: 'all 250ms',
                    backgroundColor: '#737d82',
                    _checked: {
                        backgroundColor: 'deep-sky-blue',
                        transform: 'translateX(30px)',
                    },
                },
            };
        }
    },
    defaultProps: {
        variant: 'goshare',
        size: 'lg',
    },
};

export default Switch;
