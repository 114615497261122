import PropTypes from 'prop-types';
import { WarningIcon } from '@chakra-ui/icons';
import {
    Modal as ChakraModal,
    ModalOverlay as ChakraModalOverlay,
    ModalContent as ChakraModalContent,
    ModalHeader as ChakraModalHeader,
    ModalCloseButton as ChakraModalCloseButton,
} from '@chakra-ui/react';

const Modal = ({
    isOpen,
    onClose,
    title,
    isWarning,
    children,
    ...restProps
}) => {

    return (
        <ChakraModal
            isCentered
            scrollBehavior="inside"
            isOpen={ isOpen }
            onClose={ onClose }
            { ...restProps }
        >
            <ChakraModalOverlay>
                <ChakraModalContent>
                    <ChakraModalHeader>
                        {
                            isWarning && (
                                <WarningIcon
                                    w="31px"
                                    h="31px"
                                    lineHeight="1.25"
                                    color="warning"
                                    marginRight="12px"
                                />
                            )
                        }
                        { title }
                    </ChakraModalHeader>
                    { !!onClose && (
                        <ChakraModalCloseButton />
                    ) }
                    { children }
                </ChakraModalContent>
            </ChakraModalOverlay>
        </ChakraModal>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    isWarning: PropTypes.bool,
};
Modal.defaultProps = {
    isOpen: false,
    onClose: undefined,
    title: '',
    isWarning: false,
};

export default Modal;
