import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route as BaseRoute, useHistory } from 'react-router-dom';
import { INIT, UNAUTHED, useAuthContext, } from '../../auth/useAuth';
import { LOGIN, BEFORE_LOGOUT_URL } from '../../constants/routes';
import LoadingPage from '../LoadingPage';
import { __DEV__ } from '../../utils/assertion';

export default function PrivatedRoute({ component, auth, ...restProps }) {
    const history = useHistory();
    const { authStatus } = useAuthContext();

    if (!__DEV__ && authStatus === INIT) {
        component = LoadingPage;
    }

    useEffect(() => {
        // redirect to login page
        if (authStatus === UNAUTHED) {
            sessionStorage.setItem(BEFORE_LOGOUT_URL, history.location.pathname);
            history.push({ pathname: LOGIN });
        }
    }, [history, authStatus]);

    return (
        <BaseRoute { ...restProps } component={ component } />
    );
}

PrivatedRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    auth: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

PrivatedRoute.defaultProps = {
    auth: true,
};
