import React from 'react';
import PropTypes from 'prop-types';
import { MarkerClusterer } from '@react-google-maps/api';
import scooterClusterIcon from '../../../assets/icon-mp-scooter@3x.png';
import ScooterMarker from './ScooterMarker';
import { useSelectedScooters } from '../../../contexts/SelectedScooterContext';
import { NEW_TAIPEI } from '../../../constants/city';

export const MAX_ZOOM = 13;

const clusterOptions = {
    minimumClusterSize: 1,
    maxZoom: MAX_ZOOM,
    styles: [{
        textColor: '#fff',
        url: scooterClusterIcon,
        height: 52,
        width: 40,
    }]
};

const ScooterCluster = ({ list, currentZoom, onScooterClick, cityCode }) => {
    const { selectedIds } = useSelectedScooters();
    return (
        <MarkerClusterer options={ clusterOptions }>
            { (clusterer) =>
                list.map(({ scooter_id, lat, lng, state, soc_level, idle_time, plate }) => {
                    return (
                        <ScooterMarker
                            key={ `scooter-marker-${ scooter_id }` }
                            id={ scooter_id }
                            state={ state }
                            plate={ plate }
                            socLevel={ soc_level }
                            type="電動機車"
                            idleTimeInfo={ idle_time }
                            showPlate={ currentZoom > 13 }
                            selected={ selectedIds.includes(scooter_id) }
                            onScooterClick={ onScooterClick }
                            position={ { lat, lng } }
                            clusterer={ clusterer }
                            idleOverHint={ cityCode !== undefined && cityCode === NEW_TAIPEI }
                        />
                    );
                })
            }
        </MarkerClusterer>
    );
};

ScooterCluster.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({
        scooter_id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        state: PropTypes.number,
        lat: PropTypes.number,
        lng: PropTypes.number,
    })),
    currentZoom: PropTypes.number.isRequired,
    onScooterClick: PropTypes.func.isRequired,
    cityCode: PropTypes.number,
};

ScooterCluster.defaultProps = {
    list: [],
    cityCode: undefined
};

export default ScooterCluster;
