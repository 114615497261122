import { Box } from '@chakra-ui/react';

export default function LoginContainer({ children }) {
    return (
        <Box
            width="100vw"
            height="100vh"
            zIndex="3"
            backgroundColor="#3a3f52"
            marginX="auto"
            paddingTop="240px"
            top="0"
            left="0"
            position="fixed"
        >
            { children }
        </Box>
    );
}
