import React, { useState, useCallback, createContext } from 'react';
import { Flex, HStack, Button, Box, FormLabel, Switch, Divider } from '@chakra-ui/react';
import Map from '../../components/Map';
import {
    CITY_DEFAULT_ZOOM,
    CITY_TOWN,
    FILTER_TOWN,
    FILTER_STATE,
    PLATE,
    TAIPEI,
    TYPE
} from '../../constants/city';
import { DATE_FORMAT } from '../../constants/common';
import FilterBar from './FilterBar';
import PageContainer from '../../components/PageContainer';
import useFetcher from '../../hooks/useFetcher';
import { SCOOTERS, scootersFetcher, STATISTICS, statisticsFetcher, ZONES, zonesFetcher } from '../../fetchers/scooter';
import exportFile from '../../utils/export-file';
import jsonToCSV from '../../utils/json-to-csv';
import move from '../../utils/move';
import Total from '../../components/Total/Total';
import { useMap } from '../../contexts/MapContext';
import Text from '../../components/Text/Text';
import Refresh from '../../components/Refresh/Refresh';
import { OFFLINE, IDLE, RENTING } from '../../constants/city';
import { calculateScooter, isAllowShowGSH } from '../../helpers/map';
import { SelectedScooterProvider } from '../../contexts/SelectedScooterContext';
import useUserProfile from '../../hooks/useUserProfile';
import { default as format } from 'date-fns/format';

const defaultScooterData = {
    data: {
        idle_count: 0,
        offline_count: 0,
        renting_count: 0,
        total_count: 0,
        scooter_list: [],
        city_total: {
            idle_count: 0,
            offline_count: 0,
            renting_count: 0,
            total_count: 0,
        }
    }
};
const defaultQueryPayload = {};

export const ProfileContext = createContext(null);

export const exportToCSV = async (fields, transforms, data, fileName ) => {
    const parser = await jsonToCSV({ fields, transforms });
    const csv = parser.parse(data);
    const charset = '\ufeff';
    const blob = new Blob([charset, csv], { type: 'text/csv' });

    exportFile({
        name: fileName,
        source: URL.createObjectURL(blob),
    });
};

const ScooterManagement = () => {
    const { userProfile } = useUserProfile();
    const { cityCode, googleDriveLink } = userProfile;
    const cityDefaultZoom = CITY_DEFAULT_ZOOM[cityCode];
    const [showGSH, setShowGSH] = useState();
    const [queryPayload, setQueryPayload] = useState(defaultQueryPayload);
    const { data = defaultScooterData, isValidating, mutate: mutateScooters } = useFetcher(
        [SCOOTERS, queryPayload],
        scootersFetcher,
    );
    const { data: zoneData } = useFetcher(
        [ZONES],
        zonesFetcher,
    );
    const { data: scooterData, __responseTime } = data;
    const {
        scooter_list: scooterList,
        city_total,
    } = scooterData;
    const {
        idle_count,
        offline_count,
        renting_count,
        total_count,
    } = city_total;
    const [selectedScooters, setSelectedScooters] = useState([]);
    const { state: scooterListState } = useMap();

    const handleCleanSelectedScooters = () => {
        setSelectedScooters([]);
    };

    const handleOverlayScooters = useCallback((newSelectedScooters) => {
        setSelectedScooters(prevState => {
            const list = [...prevState, ...newSelectedScooters];
            const ids = list.map(o => o.id);
            const filtered = list.filter(({ id }, index) => !ids.includes(id, index + 1));

            return filtered;
        });
    }, []);

    const handleSelectedScooter = useCallback((newSelectedScooters) => {
        setSelectedScooters(prevState => {
            let payload = prevState.map(o => o.id);
            let result = prevState;
            newSelectedScooters.forEach(item => {
                if (payload.includes(item.id)) {
                    result = result.filter(({ id }) => id !== item.id);
                }
                else {
                    result = [...result, item];
                }
            });
            return result;
        });
    }, []);

    const handleApply = type => value => {
        let partialQueryPayload = {};
        switch (type) {
        case FILTER_TOWN:
        case PLATE:
        case FILTER_STATE:
        default:
            partialQueryPayload = {
                [type]: value
            };
        }
        const mergedPayload = {
            ...queryPayload,
            ...partialQueryPayload
        };
        setQueryPayload(mergedPayload);
    };

    const selectScooter = (scooterState, list) => {
        const { mergeScooterList } = scooterListState;
        list = list || mergeScooterList;
        return list.filter(({ state }) => state === scooterState);
    };

    const refreshScooter = () => {
        mutateScooters();
    };

    const handleTypeExport = async () => {
        const response = await statisticsFetcher(STATISTICS);
        const { data } = response;
        move(data, 0, 5);

        const fields = [
            { label: '許可情形/車輛狀態', value: 'type' },
            { label: '租借中', value: 'renting_count' },
            { label: '可租借', value: 'idle_count' },
            { label: '預約中', value: 'reserve_count' },
            { label: '下線', value: 'offline_count' },
        ];

        const transforms = (item) => {
            const { renting_count, idle_count, reserve_count, offline_count } = item;
            let type = '總計';
            TYPE.map(({ text, value }) => {
                if (item.type === value) {
                    type = text;
                }
                return type;
            });
            const transformResult = {
                ...item,
                type,
                renting_count,
                idle_count,
                reserve_count,
                offline_count,
            };

            return transformResult;
        };

        exportToCSV(fields, transforms, data, `goshare_data_${ format(new Date(), DATE_FORMAT) }.csv`);
    };

    const handleExport = async () => {

        const fields = [
            { label: '公司名稱', value: 'company' },
            { label: '車種', value: 'type' },
            { label: '行政區', value: 'town' },
            { label: '車牌', value: 'plate' },
            { label: '車輛狀態', value: 'state' },
            { label: '電量', value: 'soc_level' },
            { label: '閒置時間', value: 'idle_time' },
        ];
        const scooterInTown = calculateScooter(scooterList, CITY_TOWN[cityCode].features);

        const transforms = (item) => {
            let { idle_time } = item;
            idle_time = `${ idle_time ?? 0 }`;
            let state;
            switch (item.state) {
            case OFFLINE:
                state = '下線';
                break;
            case IDLE:
                state = '可租借';
                break;
            case RENTING:
                state = '租借中';
                break;
            default:
                break;
            }

            const soc_level = `${ item.soc_level }`;
            const type = '電動機車';
            const company = '睿能數位股份有限公司';

            const transformResult = {
                ...item,
                idle_time,
                type,
                state,
                soc_level,
                company,
            };

            return transformResult;
        };
        exportToCSV(fields, transforms, scooterInTown, `goshare_data_${ format(new Date(), DATE_FORMAT) }.csv`);
    };

    const { mergeScooterList } = scooterListState;

    const selected = {
        total: selectedScooters.length,
        offline: selectScooter(0, selectedScooters).length,
        idle: selectScooter(1, selectedScooters).length,
        renting: selectScooter(2, selectedScooters).length,
    };

    const handleShowGSHChange = () => {
        setShowGSH(!showGSH);
    };

    const total = mergeScooterList.length;

    const offline = selectScooter(0).length;

    const idle = selectScooter(1).length;

    const renting = selectScooter(2).length;

    return (
        <ProfileContext.Provider value={ userProfile }>
            <SelectedScooterProvider value={ selectedScooters }>
                <PageContainer>
                    <HStack
                        alignItems={ ['end', 'start', 'start', 'center'] }
                        my="16px"
                        marginBottom={ ['5px', '5px', '5px', '16px'] }
                        width="100%"
                        flexWrap="wrap"
                        marginTop={ ['32px', '32px', '32px', '56px'] }
                        paddingBottom={ ['30px', '30px', '30px', '0'] }
                        spacing={ ['8px', '24px', '24px', 0] }
                    >
                        <Flex
                            width={ ['50%', '50%', '50%', 'auto'] }
                            flexDir={ ['column', 'column', 'column', 'row'] }
                            align={ ['start', 'start', 'start', 'center'] }
                            marginY="0"
                            flex="1"
                        >
                            <FilterBar
                                settings={ queryPayload }
                                onApply={ handleApply }
                                cityCode={ cityCode }
                            />
                            <Total
                                title="搜尋結果："
                                total={ total }
                                idle={ idle }
                                renting={ renting }
                                offline={ offline }
                                marginTop={ ['23px', '23px', '23px', 0] }
                                marginX={ [0, 0, 0, '8px'] }
                            />
                            {
                                isAllowShowGSH(cityCode) && (
                                    <>
                                        <Divider
                                            height="36px"
                                            marginX="16px"
                                            orientation="vertical"
                                            colorScheme="very-light-pink"
                                        />
                                        <Flex alignItems="center">
                                            <FormLabel
                                                marginRight="8px"
                                                marginY="0"
                                                htmlFor="isShowGSH"
                                            >
                                                顯示 GoShare 服務區域:
                                            </FormLabel>
                                            <Switch size="md" onChange={ handleShowGSHChange } />
                                        </Flex>
                                    </>
                                )
                            }
                        </Flex>
                        <Flex
                            alignItems={ ['flex-end', 'flex-end', 'flex-end', 'center'] }
                            justifyContent="end"
                            flexWrap="wrap"
                            flexDirection={ ['column-reverse', 'column-reverse', 'column-reverse', 'row'] }
                            flex="1"
                        >
                            <Total
                                title="總投放車輛："
                                total={ total_count }
                                idle={ idle_count }
                                renting={ renting_count }
                                offline={ offline_count }
                                marginLeft="auto"
                                marginRight={ [0, 0, 0, '18px'] }
                            />
                            <Box
                                alignItems="end"
                            >

                                {
                                    googleDriveLink && (
                                        <a href={ googleDriveLink } target="_blank" rel="noreferrer">
                                            <Button
                                                variant="outline"
                                                marginRight="18px"
                                                marginBottom={ ['23px', '23px', '23px', 0] }
                                            >
                                                黑名單 / 月報
                                            </Button>
                                        </a>
                                    )
                                }
                                {
                                    cityCode === TAIPEI ? (
                                        <Button
                                            variant="outline"
                                            onClick={ handleTypeExport }
                                            disabled={ isValidating }
                                            marginBottom={ ['23px', '23px', '23px', 0] }
                                        >
                                            匯出
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="outline"
                                            onClick={ handleExport }
                                            disabled={ isValidating }
                                            maxWidth="72px"
                                            marginBottom={ ['23px', '23px', '23px', 0] }
                                        >
                                            匯出
                                        </Button>
                                    )
                                }
                            </Box>
                        </Flex>
                    </HStack>
                    <Flex alignItems="center" paddingBottom="18px" flexWrap="wrap">
                        <Button
                            marginBottom={ 0 }
                            marginRight="14px"
                            variant="outline"
                            onClick={ () => setSelectedScooters([]) }
                            disabled={ selected.total === 0 }
                        >
                            清除已選車輛
                        </Button>
                        <Text
                            color="deep-sky-blue"
                            fontWeight="bold"
                        >
                            { `選擇： ${selected.total} 台` }
                        </Text>
                        <Total
                            color="deep-sky-blue"
                            title=""
                            idle={ selected.idle }
                            renting={ selected.renting }
                            offline={ selected.offline }
                            marginRight="auto"
                            marginTop={ ['23px', '23px', '23px', 0] }
                            order={ [2, 2, 2, 0] }
                        />
                        <Refresh
                            lastUploadTime={ __responseTime }
                            onClick={ refreshScooter }
                            order={ [1, 0] }
                            marginLeft={ ['auto', 'auto', 'auto', 0] }
                        />
                    </Flex>
                    <Flex position="relative">
                        { cityCode !== undefined && (
                            <Map
                                list={ scooterList }
                                onSelectZone={ handleCleanSelectedScooters }
                                onScooterClick={ handleSelectedScooter }
                                onScooterOverlay={ handleOverlayScooters }
                                selectZone={ queryPayload[FILTER_TOWN] }
                                cityCode={ cityCode }
                                zoom={ cityDefaultZoom }
                                displayGSH={ showGSH }
                                zoneData={ zoneData?.data }
                            />
                        ) }
                    </Flex>
                </PageContainer>
            </SelectedScooterProvider>
        </ProfileContext.Provider>
    );
};

export default ScooterManagement;
