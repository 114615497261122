// Number assertions
export function isNumber(value) {
    return typeof value === 'number';
}

export const isNotNumber = (value) =>
    typeof value !== 'number' || Number.isNaN(value) || !Number.isFinite(value);

export function isNumeric(value) {
    // eslint-disable-next-line eqeqeq
    return value != null && value - parseFloat(value) + 1 >= 0;
}

// Array assertions
export function isArray(value) {
    return Array.isArray(value);
}

export const isEmptyArray = (value) => isArray(value) && value.length === 0;

// Function assertions
export function isFunction(value) {
    return typeof value === 'function';
}

// Generic assertions
export const isDefined = (value) =>
    typeof value !== 'undefined' && value !== undefined;

export const isUndefined = (value) =>
    typeof value === 'undefined' || value === undefined;

// Object assertions
export const isObject = (value) => {
    const type = typeof value;
    return (
        value !== null &&
    (type === 'object' || type === 'function') &&
    !isArray(value)
    );
};

export const isEmptyObject = (value) =>
    isObject(value) && Object.keys(value).length === 0;

export function isNotEmptyObject(value) {
    return value && !isEmptyObject(value);
}

// eslint-disable-next-line eqeqeq
export const isNull = (value) => value == null;

// String assertions
export function isString(value) {
    return Object.prototype.toString.call(value) === '[object String]';
}

// Event assertions
export function isInputEvent(value) {
    return value && isObject(value) && isObject(value.target);
}

// Empty assertions
export const isEmpty = (value) => {
    if (isArray(value)) {
        return isEmptyArray(value);
    }
    if (isObject(value)) {
        return isEmptyObject(value);
    }
    // eslint-disable-next-line eqeqeq
    if (value == null || value === '') {
        return true;
    }
    return false;
};

export const __DEV__ = process.env.NODE_ENV !== 'production';
