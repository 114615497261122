import { mode } from '@chakra-ui/theme-tools';
import { transparentize } from '@chakra-ui/theme-tools';

const numericStyles = {
    '&[data-is-numeric=true]': {
        textAlign: 'right',
    },
};

const table = {
    variants: {
        goshare(props) {
            const { theme } = props;

            return {
                table: {
                    flexDirection: 'column',
                },
                thead: {
                    minWidth: 'fit-content',
                },
                th: {
                    ...theme.textStyles.medium,
                    borderRight: '2px solid white',
                    '&:last-of-type': {
                        borderRight: '0',
                    },
                    '& > span': {
                        height: '16px',
                    },
                    minWidth: 'fit-content',
                    backgroundColor: transparentize('very-light-pink', .5)(theme),
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                    lineHeight: '16px',
                    color: 'charcoal-grey',
                    padding: '12px',
                    letterSpacing: 'normal',
                    textTransform: 'initial',
                    whiteSpace: 'nowrap',
                    ...numericStyles,
                },
                td: {
                    ...numericStyles,
                },
                caption: {
                    color: mode('gray.600', 'gray.100')(props),
                },
                tbody: {
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    minWidth: 'fit-content',
                    tr: {
                        '&:nth-of-type(even)': {
                            background: transparentize('very-light-pink', .25)(theme),
                        },
                        width: '100%',
                        minWidth: 'fit-content',
                        td: {
                            ...theme.textStyles.regular,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRight: '2px solid white',
                            padding: '12px',
                            '&:last-of-type': {
                                borderRight: '0',
                            },
                            '& > span': {
                                fontSize: '14px',
                                lineHeight: '16px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                            },
                            '_checked': {
                                backgroundColor: 'black'
                            },
                        },
                    },
                },
            };
        }
    },
    defaultProps: {
        variant: 'goshare',
    }
};

export default table;
