import { transparentize } from '@chakra-ui/theme-tools';

export function baseStyle(theme) {
    return {
        ...theme.textStyles.bold,
        px: '24px',
        py: '8px',
        borderRadius: '30px',
        fontSize: '16px',
        lineHeight: 1.5,
        color: 'charcoal-grey',
        backgroundColor: transparentize('very-light-pink', .3)(theme),
        _disabled: {
            backgroundColor: transparentize('very-light-pink', .3)(theme),
            color: 'very-light-pink',
            borderColor: 'very-light-pink',
            _hover: {
                color: 'inherit'
            }
        },
        _focus: {
            outline: '0',
            boxShadow: 'none',
        }
    };
};

function linkBaseStyle(theme) {
    return {
        ...theme.textStyles.medium,
        fontSize: '14px',
        textDecoration: 'underline',
        height: 'initial',
        padding: '5px 10px',
        _focus: {
            outline: '0',
            boxShadow: 'none',
        }
    };
}

const Button = {
    variants: {
        primary({ theme }) {
            return {
                ...baseStyle(theme),
                color: 'white',
                backgroundColor: 'deep-sky-blue',
                _hover: {
                    backgroundColor: transparentize('deep-sky-blue', .7)(theme),
                }
            };
        },
        secondary({ theme }) {
            return {
                ...baseStyle(theme),
                color: 'white',
                backgroundColor: '#3bcbfd',
            };
        },
        outline({ theme }) {
            return {
                ...baseStyle(theme),
                color: 'deep-sky-blue',
                borderColor: 'deep-sky-blue',
                border: '2px solid #0275ff',
                backgroundColor: 'transparent',
                _hover: {
                    color: transparentize('deep-sky-blue', .7)(theme),
                }
            };
        },
        selectable({ theme }) {
            return {
                ...baseStyle(theme),
                ...theme.textStyles.regular,
                px: '16px',
                py: '12px',
                lineHeight: '16px',
                fontSize: '14px',
                color: 'charcoal-grey',
                backgroundColor: transparentize('very-light-pink', .3)(theme),
                position: 'relative',
            };
        },
        negative({ theme }) {
            return {
                ...baseStyle(theme),
                color: 'white',
                backgroundColor: 'bluish-grey',
                _hover: {
                    backgroundColor: transparentize('bluish-grey', .7)(theme),
                }
            };
        },
        'icon-button'({ theme }) {
            return {
                ...baseStyle(theme),
                width: '40px',
                height: '40px',
            };
        },
        'link-primary'({ theme }) {
            return {
                ...linkBaseStyle(theme),
                color: 'deep-sky-blue',
                _hover: {
                    backgroundColor: transparentize('deep-sky-blue', .1)(theme),
                }
            };
        },
        'link-cancel'({ theme }) {
            return {
                ...linkBaseStyle(theme),
                color: 'charcoal-grey',
                _hover: {
                    backgroundColor: transparentize('very-light-pink', .3)(theme),
                }
            };
        },
    },
    defaultProps: {
        variant: 'primary',
    }
};

export default Button;
