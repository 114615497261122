import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';


const SelectedScooterContext = React.createContext();

function SelectedScooterProvider({ children, value }) {

    return (
        <SelectedScooterContext.Provider value={ value }>
            { children }
        </SelectedScooterContext.Provider>
    );
};
SelectedScooterProvider.propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        state: PropTypes.number,
    })),
};

SelectedScooterProvider.defaultProps = {
    value: [],
};

function useSelectedScooters() {
    const context = useContext(SelectedScooterContext);
    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(() => {
        const list = context.map(({ id }) => id);
        setSelectedIds(list);
    }, [context]);

    return {
        selectedScooters: context || [],
        selectedIds: selectedIds || [],
    };
}

export { SelectedScooterProvider, useSelectedScooters };
