import { extendTheme } from '@chakra-ui/react';
import colors from './colors';
import Button from './button';
import Badge from './badge';
import Checkbox from './checkbox';
import Radio from './radio';
import Switch from './switch';
import textStyles from './textStyles';
import Table from './table';
import Text from './text';
import Input from './input';
import Select from './select';
import Tooltip from './tooltip';
import Popover from './popover';
import Modal from './modal';
import Link from './link';
import FormError from './formError';
import Heading from './Heading';

/**
 * @type { import('@chakra-ui/react').Theme }
 */
const theme = extendTheme({
    components: {
        Checkbox,
        Radio,
        Badge,
        Button,
        Switch,
        Table,
        Text,
        Input,
        Select,
        Tooltip,
        Popover,
        Modal,
        Link,
        FormError,
        Heading,
    },
    colors,
    textStyles,
});

export default theme;
