import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverFooter,
    Box,
    Stack,
    Button,
    useDisclosure,
} from '@chakra-ui/react';
import Checkbox from '../../Checkbox/Checkbox';
import SelectableButton from '../SelectableButton';
import { isEmpty } from '../../../utils/assertion';

export const SELECT_TYPE_MULTI = 'multi';
export const SELECT_TYPE_SINGLE = 'single';

export default function MultiSelect(props) {
    const { title, options, selected: selectedProps, onApply, className, type } = props;
    const { isOpen, onClose, onToggle } = useDisclosure();
    const [selectedState, setSelectedState] = useState([]);
    const applied = selectedProps.length > 0;

    function handleChange(value) {
        if (type === SELECT_TYPE_SINGLE) {
            setSelectedState([value]);
        }
        else {
            // directly use value instead of event, because html input event would change target value type to string
            if (selectedState.includes(value)) {
                const selected = selectedState.filter(v => v !== value);
                setSelectedState(selected);
            }
            else {
                selectedState.push(value);
                setSelectedState([...selectedState]);
            }
        }
    }

    const handleApply = () => {
        onClose();
        onApply(!isEmpty(selectedState) ? selectedState : undefined );
    };

    const handleReset = (e) => {
        e.stopPropagation();
        setSelectedState([]);
        onApply(undefined);
        onClose();
    };

    let tipText = '';

    if (applied) {
        const selectedTextofOptions = options
            .filter(option => selectedProps.includes(option.value))
            .map(option => option.text);
        tipText += `${ selectedTextofOptions.join(', ') }`;
    }

    // while popover is opening, initial `selectedState` using `selectedProps`
    useEffect(() => {
        if (isOpen) {
            setSelectedState([...selectedProps]);
        }
    }, [selectedProps, isOpen]);

    return (
        <Box className={ className }>
            <Popover
                variant="multi-select"
                placement="bottom-start"
                isOpen={ isOpen }
                onClose={ onClose }
                lazyBehavior="keepMounted"
                isLazy
            >
                <PopoverTrigger>
                    <SelectableButton
                        onClick={ onToggle }
                        isOpen={ isOpen }
                        showTooltip={ applied }
                        tooltipLabel={ tipText }
                        badgeNumber={ selectedProps.length }
                    >
                        { title }
                    </SelectableButton>
                </PopoverTrigger>
                <PopoverContent paddingBottom="48px">
                    <PopoverBody>
                        <Stack maxHeight="260px" spacing="4px" overflow="auto" paddingBottom="40px">
                            { options.map(({ value, text }) => (
                                <Checkbox
                                    variant="multi-select-checkbox"
                                    key={ value }
                                    value={ value }
                                    isChecked={ selectedState.includes(value) }
                                    onChange={ () => handleChange(value) }
                                >
                                    { text }
                                </Checkbox>
                            )) }
                        </Stack>
                    </PopoverBody>
                    <PopoverFooter>
                        <Button variant="link-cancel" onClick={ handleReset }>清除</Button>
                        <Button variant="link-primary" onClick={ handleApply }>套用</Button>
                    </PopoverFooter>
                </PopoverContent>
            </Popover>
        </Box>
    );
}

MultiSelect.propTypes = {
    title: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    selected: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ])),
    onApply: PropTypes.func,
    className: PropTypes.string,
    type: PropTypes.string,
};

MultiSelect.defaultProps = {
    selected: [],
    onApply: () => {},
    className: '',
    type: SELECT_TYPE_MULTI,
};
