import { Box, Flex } from '@chakra-ui/react';
import Header from '../Header/Header';

export default function PageContainer({ children }) {

    return (
        <Flex as="main" direction="column" flex="1" width="100vw">
            <Header />
            <Box mx={ ['14px', '24px'] }>
                { children }
            </Box>
        </Flex>
    );
}
