import { useEffect } from 'react';
import createPersistedState from 'use-persisted-state';
import { mutateSigninSilent } from './useAuth';
import useUserProfile from '../hooks/useUserProfile';
import usePrevious from '../hooks/usePrevious';

const useSessionChanged = createPersistedState('session-changed');

export const CHANGED = 'CHANGED';
export const UNCHANGED = 'UNCHANGED';

export default function useSessionMonitor() {
    const [sessionChanged, setSessionChanged] = useSessionChanged(UNCHANGED);
    const prevSessionChanged = usePrevious(sessionChanged);
    const { mutateUserProfile } = useUserProfile();

    useEffect(() => {
        if (prevSessionChanged === UNCHANGED && sessionChanged === CHANGED) {
            mutateSigninSilent();
            mutateUserProfile();
        }
    }, [prevSessionChanged, sessionChanged, mutateUserProfile]);

    return { sessionChanged, setSessionChanged };
}
