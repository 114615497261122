import PropTypes from 'prop-types';
import { Flex, useMediaQuery } from '@chakra-ui/react';
import Text from '../Text/Text';

export default function Total({
    title,
    total,
    idle,
    renting,
    offline,
    ...restProps
}) {
    const [isSmallerThan320] = useMediaQuery('(max-width: 320px)');

    return (
        <Flex
            align="center"
            { ...restProps }
            borderWidth={ ['1px', '1px', '1px', 0] }
            borderStyle="solid"
            borderColor="very-light-pink"
            borderRadius="2px"
            padding={ ['15px', '24px', '24px', 0] }
            flexDirection={ ['column', 'column', 'column', 'row'] }
            width={ ['100%', '100%', '100%', 'auto'] }
        >
            {
                title ? (
                    <Text
                        fontWeight="bold"
                        colorScheme="blackAlpha"
                        width={ ['100%', '100%', '100%', 'auto'] }
                        borderBottomWidth={ ['1px', '1px', '1px', 0] }
                        borderStyle="solid"
                        borderColor="very-light-pink"
                        paddingBottom={ ['16px', '16px', '16px', 0] }
                        marginBottom={ ['16px', '16px', '16px', 0] }
                        display={ isSmallerThan320 ? 'flex' : 'block' }
                        flexDirection={ isSmallerThan320 ? 'column' : 'row' }
                    >
                        <span>{ title }</span>
                        <span>{ total }</span>
                    </Text>
                ) : null
            }

            <Text display={ ['none', 'none', 'none', 'inherit'] }>&nbsp;(</Text>
            <Flex
                width={ ['100%', '100%', '100%', 'auto'] }
                display="flex"
                justifyContent="space-between"
            >
                <Text variant="idle" value="可租借 :" marginRight="3px" />
                <Text variant="idle" value={ idle } />
            </Flex>
            <Text marginX="3px" display={ ['none', 'none', 'none', 'inherit'] }>/</Text>
            <Flex
                width={ ['100%', '100%', '100%', 'auto'] }
                display="flex"
                justifyContent="space-between"
            >
                <Text variant="renting" value="租借中 :" marginRight="3px" />
                <Text variant="renting" value={ renting } />
            </Flex>
            <Text marginX="3px" display={ ['none', 'none', 'none', 'inherit'] }>/</Text>
            <Flex
                width={ ['100%', '100%', '100%', 'auto'] }
                display="flex"
                justifyContent="space-between"
            >
                <Text variant="offline" value="下線 :" marginRight="3px" />
                <Text variant="offline" value={ offline } />
            </Flex>
            <Text display={ ['none', 'none', 'none', 'inherit'] }>)</Text>
        </Flex>
    );
};

Total.propTypes = {
    title: PropTypes.string,
    total: PropTypes.number,
    idle: PropTypes.number,
    renting: PropTypes.number,
    offline: PropTypes.number,
};

Total.defaultProps = {
    title: '',
    total: 0,
    idle: 0,
    renting: 0,
    offline: 0,
};
