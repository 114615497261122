import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from '@chakra-ui/react';

const ListItem = ({ title, children }) => {
    return (
        <Flex justifyContent="space-between">
            <Text colorScheme="blackAlpha">
                { title } 
            </Text>
            { children }
        </Flex>
    );
};

ListItem.propTypes = {
    title: PropTypes.string,
};

ListItem.defaultProps = {
    title: '',
};

export default ListItem;