import { transparentize } from '@chakra-ui/theme-tools';

const baseStyle = ({ theme, baseSpace, scrollBehavior }) => ({
    overlay: {
        bg: transparentize('black', .5)(theme),
    },
    dialogContainer: {},
    dialog: {
        borderRadius: '0',
        width: '800px',
        opacity: '1 !important',
        minHeight: scrollBehavior === 'inside' ? '296px' : undefined,

    },
    header: {
        ...(theme.components.Text.variants.title({ theme })),
        fontSize: '36px',
        px: baseSpace,
        py: baseSpace,
        paddingBottom: '32px',
        display: 'flex',
        alignItems: 'center',
    },
    closeButton: {
        width: '40px',
        height: '40px',
        top: baseSpace,
        right: baseSpace,
        insetEnd: baseSpace,
        fontSize: '23px',
        _focus: {
            outline: 'none',
        },
        _hover: {
            bg: 'transparent',
        },
        _active: {
            bg: 'transparent',
        }
    },
    body: {
        mx: baseSpace,
        px: 0,
        paddingTop: 0,
        paddingBottom: '40px',
    },
    footer: {
        px: baseSpace,
        py: baseSpace,
    },
});

const sizes = {
    base: {
        dialog: {
            maxW: '800px',
        }
    },
    'document-preview': {
        dialog: {
            width: 'fit-content',
            minWidth: '960px',
        }
    }
};

const defaultProps = {
    size: 'base',
    baseSpace: '48px',
};

const Modal = {
    baseStyle,
    sizes,
    defaultProps,
};

export default Modal;
