import town_taipei from '../data/city_taipei.json';
import town_new_taipei from '../data/city_newtaipei.json';
import town_kaohsiung from '../data/city_kaohsiung.json';
import town_tainan from '../data/city_tainan.json';
import town_taichung from '../data/city_taichung.json';

export const OFFLINE = 0;
export const IDLE = 1;
export const RENTING = 2;

export const TAIPEI_ONLY = 1;
export const NEW_TAIPEI_ONLY = 2;
export const TAIPEI_NEW_TAIPEI = 3;
export const EXCLUDE_TAIPEI_NEW_TAIPEI = 4;


export const FILTER_TOWN = 'town';
export const FILTER_STATE = 'state';
export const FILTER_TYPE = 'type';
export const PLATE = 'plate';

export const TAIPEI = 0;
export const NEW_TAIPEI = 3;
export const KAOHSIUNG = 5;
export const TAINAN = 2;
export const TAICHUNG = 7;

export const STATE = [
    RENTING,
    IDLE,
    OFFLINE
];

export const TYPE = [
    { text: '僅台北市許可', value: TAIPEI_ONLY  },
    { text: '僅新北市許可', value: NEW_TAIPEI_ONLY  },
    { text: '雙北市許可', value: TAIPEI_NEW_TAIPEI  },
    { text: '非屬以上', value: EXCLUDE_TAIPEI_NEW_TAIPEI  },
];

export const TAIPEI_TOWN = [
    '松山區',
    '信義區',
    '大安區',
    '中山區',
    '中正區',
    '大同區',
    '萬華區',
    '文山區',
    '南港區',
    '內湖區',
    '士林區',
    '北投區',
];

export const NEW_TAIPEI_TOWN = [
    '板橋區',
    '三重區',
    '中和區',
    '永和區',
    '新莊區',
    '新店區',
    '土城區',
    '蘆洲區',
    '汐止區',
    '樹林區',
    '鶯歌區',
    '三峽區',
    '淡水區',
    '瑞芳區',
    '五股區',
    '泰山區',
    '林口區',
    '八里區',
    '深坑區',
    '石碇區',
    '坪林區',
    '三芝區',
    '石門區',
    '金山區',
    '萬里區',
    '平溪區',
    '雙溪區',
    '貢寮區',
    '烏來區',
];

export const KAOHSIUNG_TOWN = [
    '鹽埕區',
    '鼓山區',
    '左營區',
    '楠梓區',
    '三民區',
    '新興區',
    '前金區',
    '苓雅區',
    '前鎮區',
    '旗津區',
    '小港區',
    '鳳山區',
    '林園區',
    '大寮區',
    '大樹區',
    '大社區',
    '仁武區',
    '鳥松區',
    '岡山區',
    '橋頭區',
    '燕巢區',
    '田寮區',
    '阿蓮區',
    '路竹區',
    '湖內區',
    '茄萣區',
    '永安區',
    '彌陀區',
    '梓官區',
    '旗山區',
    '美濃區',
    '六龜區',
    '甲仙區',
    '杉林區',
    '內門區',
    '茂林區',
    '桃源區',
    '那瑪夏區',
];

export const TAINAN_TOWN = [
    '新營區',
    '鹽水區',
    '白河區',
    '柳營區',
    '後壁區',
    '東山區',
    '麻豆區',
    '下營區',
    '六甲區',
    '官田區',
    '大內區',
    '佳里區',
    '學甲區',
    '西港區',
    '七股區',
    '將軍區',
    '北門區',
    '新化區',
    '善化區',
    '新市區',
    '安定區',
    '山上區',
    '玉井區',
    '楠西區',
    '南化區',
    '左鎮區',
    '仁德區',
    '歸仁區',
    '關廟區',
    '龍崎區',
    '永康區',
    '東區',
    '南區',
    '北區',
    '安南區',
    '安平區',
    '中西區',
];

export const TAICHUNG_TOWN = [
    '中區',
    '東區',
    '南區',
    '西區',
    '北區',
    '西屯區',
    '南屯區',
    '北屯區',
    '豐原區',
    '東勢區',
    '大甲區',
    '清水區',
    '沙鹿區',
    '后里區',
    '神岡區',
    '潭子區',
    '大雅區',
    '新社區',
    '石岡區',
    '外埔區',
    '大安區',
    '烏日區',
    '大肚區',
    '龍井區',
    '霧峰區',
    '太平區',
    '大里區',
    '和平區',
    '梧棲區',
];

export const CITY_LIST = {
    [TAIPEI]: TAIPEI_TOWN,
    [NEW_TAIPEI]: NEW_TAIPEI_TOWN,
    [KAOHSIUNG]: KAOHSIUNG_TOWN,
    [TAINAN]: TAINAN_TOWN,
    [TAICHUNG]: TAICHUNG_TOWN,
};

export const CITY_TOWN = {
    [TAIPEI]: town_taipei,
    [NEW_TAIPEI]: town_new_taipei,
    [KAOHSIUNG]: town_kaohsiung,
    [TAINAN]: town_tainan,
    [TAICHUNG]: town_taichung,
};

export const CITY_CENTER = {
    [TAIPEI]: {
        lat: 25.0375,
        lng: 121.5638,
    },
    [NEW_TAIPEI]: {
        lat: 25.0123,
        lng: 121.4655,
    },
    [KAOHSIUNG]: {
        lat: 22.6212,
        lng: 120.3122,
    },
    [TAINAN]: {
        lat: 22.9923,
        lng: 120.1850,
    },
    [TAICHUNG]: {
        lat: 24.1618,
        lng: 120.6468,
    },
};

export const CITY_NAME = {
    [TAIPEI]: '台北市',
    [NEW_TAIPEI]: '新北市',
    [KAOHSIUNG]: '高雄市',
    [TAINAN]: '臺南市',
    [TAICHUNG]: '臺中市',
};

export const CITY_DEFAULT_ZOOM = {
    [TAIPEI]: 12,
    [NEW_TAIPEI]: 10,
    [KAOHSIUNG]: 10,
    [TAINAN]: 10,
    [TAICHUNG]: 10,
};
