function messageBaseStyle(theme) {
    return {
        ...theme.textStyles.medium,
        fontSize: '14px',
        lineHeight: 'normal',
        fontWeight: 500,
        color: '#000000',
    };
};

function textBaseStyle(theme) {
    return {
        ...theme.textStyles.regular,
        color: 'charcoal-grey',
        fontSize: '14px',
        lineHeight: '24px',
    };
};

const Text = {
    variants: {
        title({ theme }) {
            return {
                ...theme.textStyles.regular,
                color: 'charcoal-grey',
                fontSize: '32px',
                lineHeight: '1.25',
            };
        },
        subtitle({ theme }) {
            return {
                ...theme.textStyles.regular,
                color: '#4a4a4a',
                fontSize: '24px',
                lineHeight: 1.67,
                fontWeight: '300'
            };
        },
        content() {
            return {
                fontSize: '16px',
                lineHeight: 'normal',
                color: '#000000'
            };
        },
        message({ theme }) {
            return {
                ...messageBaseStyle(theme),
            };
        },
        'error-message'({ theme }) {
            return {
                ...messageBaseStyle(theme),
                fontSize: '12px',
                color: 'strawberry'
            };
        },
        success({ theme }) {
            return {
                ...messageBaseStyle(theme),
                color: 'shamrock'
            };
        },
        failed({ theme }) {
            return {
                ...messageBaseStyle(theme),
                color: 'strawberry'
            };
        },
        'message-title'({ theme }) {
            return {
                ...messageBaseStyle(theme),
                ...theme.textStyles.regular,
                color: '#737d82'
            };
        },
        value({ theme }) {
            return {
                ...textBaseStyle(theme)
            };
        },
        key({ theme }) {
            return {
                ...textBaseStyle(theme),
                ...theme.textStyles.medium,
            };
        },
        idle({ theme }) {
            return {
                ...textBaseStyle(theme),
                ...theme.textStyles.bold,
                color: 'green',
            };
        },
        renting({ theme }) {
            return {
                ...textBaseStyle(theme),
                ...theme.textStyles.bold,
                color: 'orange',
            };
        },
        offline({ theme }) {
            return {
                ...textBaseStyle(theme),
                ...theme.textStyles.bold,
                color: 'offline-gray',
            };
        }
    },
    defaultProps: {
        variant: 'message',
        colorScheme: 'deep-sky-blue',
    }
};

export default Text;
