function baseStyle() {
    return {
        popper: {
            width: 'initial',
            maxWidth: 'initial',
        },
        content: {
            position: 'relative',
            border: 'none',
            borderRadius: '10px',
            padding: '8px',
            boxShadow: '0 5px 10px 0 rgba(0, 0, 0, .2)',
            _focus: {
                outline: 0,
                boxShadow: '0 5px 10px 0 rgba(0, 0, 0, .2)'
            }
        },
        body: {
            padding: '0'
        },
        footer: {
            display: 'flex',
            padding: '16px 24px 16px 24px',
            justifyContent: 'space-between',
            border: '0'
        }
    };
}

const Popover = {
    variants: {
        'multi-select'() {
            const style = baseStyle();
            style.content = {
                ...style.content,
                width: '251px',
                minWidth: 'fit-content',
            };
            style.footer = {
                ...style.footer,
                alignItems: 'flex-end',
                position: 'absolute',
                bottom: '0',
                left: '0',
                padding: '0px 24px 16px 24px',
                height: '48px',
                width: '100%',
                backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff 50%)',
                borderRadius: '10px'
            };

            return {
                ...style
            };
        },
        'datetime-range-select'() {
            const style = baseStyle();
            style.content = {
                ...style.content,
                width: 'initial',
            };

            return {
                ...style
            };
        }
    }
};

export default Popover;
