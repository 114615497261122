const common = {
    login: 'Login',
    totalCount: 'Total Count',
    totalPage: 'Total Page',
    lastUpload: 'Last Upload',
    online: 'Online',
    offline: 'Offline',
    take: 'Take',
    startTime: 'Start time',
    endTime: 'End time',
    apply: 'Apply',
    clear: 'Clear',
    pageNumber: 'Page Number',
    approved: 'Approved',
    approve: 'Approve',
    rejected: 'Rejected',
    reject: 'Reject',
    cancel: 'Cancel',
    submit: 'Submit',
    birthday: 'Birthday',
    name: 'Name',
    country: 'Country',
    save: 'Save',
    pleaseLogin: 'Please log in again',
    pleaseLoginMessage: (
        'To keep your work secure, your session has timed out. ' +
        'To log back in, please refresh your browser, or hit Log In below.'
    ),
    ok: 'OK',
    loginFailed: 'Login Failed',
};

export default common;
