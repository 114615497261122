import { useState, useRef, useMemo, useCallback } from 'react';
import { useOutsideClick } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import InfoPanel from './InfoPanel';
import { Marker } from '@react-google-maps/api';
import { OFFLINE, IDLE, RENTING } from '../../../constants/city';
import ScooterIcon_IDLE from '../../../assets/map-pin/idle.svg';
import ScooterIcon_IDLE_SELECT from '../../../assets/map-pin/idle-selected.svg';
import ScooterIcon_OFFLINE from '../../../assets/map-pin/offline.svg';
import ScooterIcon_OFFLINE_SELECT from '../../../assets/map-pin/offline-selected.svg';
import ScooterIcon_RENTING from '../../../assets/map-pin/renting.svg';
import ScooterIcon_RENTING_SELECT from '../../../assets/map-pin/renting-selected.svg';
import ScooterIcon_WARNING from '../../../assets/map-pin/warning.svg';
import ScooterIcon_WARNING_SELECT from '../../../assets/map-pin/warning-selected.svg';

const WARNING_BY_HOURS = 72;

export const getScooterIcon = ({
    state,
    selected,
    idleTimeInfo,
    idleOverHint,
}) => {
    const { Size } = window.google.maps || {};

    const getMarkerIcon = (state, idleTimeInfo) => {

        if (idleOverHint && state === IDLE && idleTimeInfo > WARNING_BY_HOURS) {
            return {
                primary: ScooterIcon_WARNING,
                actived: ScooterIcon_WARNING_SELECT
            };
        }

        switch (state) {
        case IDLE:
            return {
                primary: ScooterIcon_IDLE,
                actived: ScooterIcon_IDLE_SELECT
            };
        case RENTING:
            return {
                primary: ScooterIcon_RENTING,
                actived: ScooterIcon_RENTING_SELECT
            };
        case OFFLINE:
            return {
                primary: ScooterIcon_OFFLINE,
                actived: ScooterIcon_OFFLINE_SELECT
            };
        default:
        }
    };
    const { primary, actived } = getMarkerIcon(state, idleTimeInfo);

    let icon = primary;

    if (selected) {
        icon = actived;
    }

    return {
        url: icon,
        scaledSize: new Size(16, 16),
    };
};

const ScooterMarker = ({
    id, position: { lat, lng }, state, plate, socLevel, type, idleTimeInfo,
    onScooterClick, selected, clusterer, idleOverHint
}) => {
    const [showInfoPanel, setShowInfoPanel] = useState(false);
    const panelRef = useRef();
    const position = useMemo(() => ({ lat, lng }), [lat, lng]);
    const scooterIcon = useMemo(() => getScooterIcon({ state, selected, idleTimeInfo, idleOverHint }),
        [selected, state, idleTimeInfo, idleOverHint]);

    useOutsideClick({
        ref: panelRef,
        handler: (e) => {
            if (showInfoPanel) {
                setShowInfoPanel(false);
            }
        }
    });

    const handleSelect = useCallback(() => {
        onScooterClick([{ id, state }]);
        setShowInfoPanel(!selected);
    }, [id, state, onScooterClick, selected]);


    return (
        <>
            { showInfoPanel && (
                <InfoPanel
                    ref={ panelRef }
                    plate={ plate }
                    state={ state }
                    socLevel={ socLevel }
                    type={ type }
                    idleTimeInfo={ idleTimeInfo }
                    position={ position }
                />
            ) }
            <Marker
                key={ `scooter-marker-${ id }` }
                position={ position }
                icon={ scooterIcon }
                onClick={ handleSelect }
                clusterer={ clusterer }
            />
        </>
    );
};


ScooterMarker.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    position: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    }).isRequired,
    state: PropTypes.number,
    plate: PropTypes.string,
    socLevel: PropTypes.number,
    type: PropTypes.string,
    idleTimeInfo: PropTypes.number,
    selected: PropTypes.bool,
    onScooterClick: PropTypes.func,
    clusterer: PropTypes.shape(),
    idleOverHint: PropTypes.bool,
};

ScooterMarker.defaultProps = {
    state: 0,
    plate: '',
    socLevel: 0,
    type: '',
    idleTimeInfo: 0,
    selected: false,
    onScooterClick: () => {},
    clusterer: undefined,
    idleOverHint: false,
};

export default ScooterMarker;
