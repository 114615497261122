import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { OverlayView } from '@react-google-maps/api';

const CurrentMarker = ({ lat, lng }) => {
    return (
        <OverlayView
            position={ { lat, lng } }
            mapPaneName={ OverlayView.OVERLAY_MOUSE_TARGET }
        >
            <Box
                as="div"
                style={ {
                    border: '2px solid rgba(0, 105, 210, .2)',
                    width: '90px',
                    height: '90px',
                    background: 'rgba(0, 105, 210, .1)',
                    borderRadius: '50%',
                    transform: 'translate(-50%, -50%)',
                    position: 'absolute',
                    zIndex: '-1',
                } }
                _after={ {
                    content: '""',
                    background: '#0069d2',
                    border: '2px solid #fff',
                    borderRadius: '50%',
                    position: 'absolute',
                    WebkitTransform: 'translateX(0)',
                    transform: 'translateX(0)',
                    top: '0',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    margin: 'auto',
                    height: '16px',
                    width: '16px',
                } }
            />
        </OverlayView>
    );
};

CurrentMarker.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
};

export default CurrentMarker;
