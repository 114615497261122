const FormError = {
    variants: {
        goshare({ theme }) {
            return {
                text: {
                    ...theme.textStyles.medium,
                    color: 'strawberry',
                    fontSize: '12px',
                    height: '16px',
                }
            };
        }
    },
    defaultProps: {
        variant: 'goshare'
    }
};

export default FormError;
