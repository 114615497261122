import { grApiInstance } from './utils';

export const SCOOTERS = '/v1.0/maps/scooters';
export const STATISTICS = '/v1.0/maps/statistics';
export const ZONES = 'v1.0/zones';

export function zonesFetcher(...key) {
    const [zones] = key;

    return grApiInstance.get(
        zones
    );
}

export function scootersFetcher(...key) {
    const [scooters, params] = key;

    return grApiInstance.get(
        scooters, { params }
    );
}

export function statisticsFetcher(...key) {
    const [statistics] = key;

    return grApiInstance.get(
        statistics
    );
}
