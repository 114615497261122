import useAuth, { AuthProvider, } from '../../auth/useAuth';
import { MapProvider } from '../../contexts/MapContext';

export default function GlobalStateProvider({ children }) {
    const auth = useAuth();
    return (
        <AuthProvider value={ auth }>
            <MapProvider>
                { children }
            </MapProvider>
        </AuthProvider>
    );
}
