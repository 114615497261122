import axios from 'axios';
import qs from 'query-string';
import xss from 'xss';
import pipe from '../utils/pipe';
import { __DEV__, isString } from '../utils/assertion';
import { getAccessToken } from '../auth/userManager';
import { updatingAuthPromise } from '../auth/useAuth';

/**
 * Axios types
 * @typedef { import('axios').AxiosRequestConfig } AxiosRequestConfig
 * @typedef { import('axios').AxiosInstance } AxiosInstance
**/

/**
 * @param { AxiosRequestConfig } config - AxiosRequestConfig
 * @return { AxiosInstance } AxiosInstance
**/
function createAxiosInstance(config = {}) {
    return axios.create({ ...config });
};

/**
 * @param { AxiosInstance } axiosInstance
 * @return { AxiosInstance }
**/
function withParamsSerializer(axiosInstance) {
    axiosInstance.defaults.paramsSerializer = params => (
        qs.stringify(params)
    );

    return axiosInstance;
}

function xssProtection(data) {
    return JSON.parse(JSON.stringify(data), (key, value) => (
        isString(value) ? xss(value) : value
    ));
}

/**
 * @param { AxiosInstance } axiosInstance
 * @return { AxiosInstance }
**/
function withRequestXSSProtection(axiosInstance) {
    axiosInstance.interceptors.request.use(config => {
        if (config.params) {
            config.params = xssProtection(config.params);
        }

        if (config.data) {
            config.data = xssProtection(config.data);
        }

        return config;
    });

    return axiosInstance;
}

/**
 * @param { AxiosInstance } axiosInstance
 * @return { AxiosInstance }
**/
function withWaitingAuth(axiosInstance) {
    axiosInstance.interceptors.request.use(async config => {
        await updatingAuthPromise();

        return config;
    });

    return axiosInstance;
}

/**
 * @param { AxiosRequestConfig } config - AxiosRequestConfig
 * @return { AxiosRequestConfig } AxiosRequestConfig
**/
async function setToken(config) {
    if (__DEV__) {
        config.headers.common['Authorization'] = (
            `Bearer ${ process.env.REACT_APP_API_TOKEN }`
        );
    }
    else {
        const { access_token, token_type } = await getAccessToken();
        config.headers.common['Authorization'] = (
            `${ token_type } ${ access_token }`
        );
    }

    return config;
}

/**
 * @param { AxiosInstance } axiosInstance
 * @return { AxiosInstance }
**/
function withToken(axiosInstance) {
    axiosInstance.interceptors.request.use(setToken);

    return axiosInstance;
}

/**
 * @param { AxiosInstance } axiosInstance
 * @return { AxiosInstance }
**/
function withResponseHandler(axiosInstance) {
    axiosInstance.interceptors.response.use(
        ({ data }) => data
    );

    return axiosInstance;
}

/**
 * @param { AxiosInstance } axiosInstance
 * @return { AxiosInstance }
**/
function withResponseXSSProtection(axiosInstance) {
    axiosInstance.interceptors.response.use(data => {
        if (data) {
            data = xssProtection(data);
        }

        return data;
    });

    return axiosInstance;
}

/**
 * @param { AxiosInstance } axiosInstance
 * @return { AxiosInstance }
**/
function withErrorResponseHandler(axiosInstance) {
    axiosInstance.interceptors.response.use(
        null,
        error => {
            const data = error.response?.data;
            const { status } = error.response ?? {};
            return Promise.reject({ data, status });
        }
    );

    return axiosInstance;
}

/**
 * @param { AxiosInstance } axiosInstance
 * @return { AxiosInstance }
**/
function withResponseTimestamp(axiosInstance) {
    axiosInstance.interceptors.response.use(data => {
        if (data) {
            data = {
                data,
                __responseTime: new Date().toISOString()
            };
        }
        return data;
    });

    return axiosInstance;
}

/**
 * @type { AxiosRequestConfig }
**/
const googleAuthApiConfig = {
    baseURL: process.env.REACT_APP_GOOGLE_AUTH_URL,
    withCredentials: true,
};

export const googleAuthApiInstance = pipe(
    createAxiosInstance,
    withParamsSerializer,
    withRequestXSSProtection,
    withResponseHandler,
    withResponseXSSProtection,
    withErrorResponseHandler,
)(googleAuthApiConfig);

/**
 * @type { AxiosRequestConfig }
**/
const CancelToekn = axios.CancelToken;
export const source = CancelToekn.source();

const grApiConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    cancelToken: source.token,
};

export const grApiInstance = pipe(
    createAxiosInstance,
    withParamsSerializer,
    withRequestXSSProtection,
    withToken,
    withWaitingAuth,
    withResponseHandler,
    withResponseXSSProtection,
    withErrorResponseHandler,
    withResponseTimestamp,
)(grApiConfig);
