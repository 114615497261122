import React from 'react';
import PropTypes from 'prop-types';
import { Text, Box, Divider } from '@chakra-ui/react';
import ListItem from '../../../components/ListItem';

const InfoPanel = ({ list, selectedIds }) => {
    return (
        <Box
            as="div"
            position="absolute"
            right="10px"
            top="10px"
            padding="12px"
            borderRadius="5px"
            boxShadow="0 1px 4px 0 rgba(0, 0, 0, 0.15)"
            backgroundColor="#ffffff"
            width="20%"
        >
            <ListItem title="Total">
                <Text color="#blackAlpha">
                    { list.filter(({ scooter_id }) => selectedIds.includes(scooter_id)).length }
                </Text>
            </ListItem>
            <Divider margin="6px 0" orientation="horizontal" colorScheme="very-light-pink" />
            <ListItem title="Offline">
                <Text color="#blackAlpha">
                    { list.filter(({ scooter_id }) => selectedIds.includes(scooter_id))
                        .filter(({ state }) => state === 0).length }   
                </Text>
            </ListItem>
            <Divider margin="6px 0" orientation="horizontal" colorScheme="very-light-pink" />
            <ListItem title="Idle">
                <Text color="#blackAlpha">
                    { list.filter(({ scooter_id }) => selectedIds.includes(scooter_id))
                        .filter(({ state }) => state === 1).length }   
                </Text>
            </ListItem>
            <Divider margin="6px 0" orientation="horizontal" colorScheme="very-light-pink" />
            <ListItem title="Renting">
                <Text color="#blackAlpha">
                    { list.filter(({ scooter_id }) => selectedIds.includes(scooter_id))
                        .filter(({ state }) => state === 2).length }    
                </Text>
            </ListItem>
        </Box>
    );
};

InfoPanel.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({
        scooter_id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        state: PropTypes.number,
        lat: PropTypes.number,
        lng: PropTypes.number,
    })),
    selectedIds: PropTypes.arrayOf(PropTypes.string),
};

InfoPanel.defaultProps = {
    list: [],
    selectedIds: [],
};


export default InfoPanel;