function baseStyle(theme) {
    return {
        ...theme.textStyles.medium,
        px: '8px',
        py: '2px',
        height: '20px',
        borderRadius: '4px',
        fontSize: '14px',
        lineHeight: 'normal',
        textTransform: 'inherit',
        color: 'white',
        backgroundColor: 'blue.500',
    };
};

const Badge = {
    variants: {
        danger({ theme }) {
            return {
                ...baseStyle(theme),
                backgroundColor: 'strawberry',
            };
        },
        success({ theme }) {
            return {
                ...baseStyle(theme),
                backgroundColor: 'shamrock',
            };
        },
        note({ theme }) {
            return {
                ...baseStyle(theme),
            };
        },
        number({ theme }) {
            return {
                ...baseStyle(theme),
                ...theme.textStyles.bold,
                borderRadius: '10px',
                height: '20px',
                minWidth: '20px',
                padding: '3px 6px 2px',
                fontSize: '13px',
                backgroundColor: 'deep-sky-blue',
            };
        }
    },
    defaultProps: {
        variant: 'success',
    },
};

export default Badge;
