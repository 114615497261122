import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@chakra-ui/react';
import { FaLocationArrow } from 'react-icons/fa';

const CurrentLocation = ({ onPositionClick, disabled }) => {
    return (
        <IconButton
            aria-label="Localtion map"
            position="absolute"
            style={ { backgroundColor: '#ffffff', color: '#666' } }
            left={ [ '13px', '13px', '13px', '13px', '9px' ] }
            top={ [ '122px', '122px', '122px', '122px', '120px' ] }
            disabled={ disabled }
            icon={ <FaLocationArrow /> }
            onClick={ onPositionClick }
            borderRadius="2px"
            boxShadow="rgba(0, 0, 0, 0.3) 0px 1px 4px -1px"
        />
    );
};

CurrentLocation.propTypes = {
    onPositionClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

CurrentLocation.defaultProps = {
    disabled: true,
};


export default CurrentLocation;
