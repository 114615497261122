import { lazy } from 'react';
import {
    ROOT,
    LOGIN,
    REDIRECT,
    LOGOUT_REDIRECT,
} from '../../constants/routes';

const ScooterManagement = lazy(() => import('../../pages/ScooterManagement'));
const Login = lazy(() => import('../../pages/Login/Login'));
const Redirect = lazy(() => import('../../pages/Redirect/Redirect'));
const LogoutRedirect = lazy(() => import('../../pages/Logout/LogoutRedirect'));

/**
 * @type { { path: string, auth: string | boolean, component: React.FunctionComponent }[] }
 */
const config = [{
    path: ROOT,
    auth: true,
    component: ScooterManagement,
    isMenuItem: false,
}, {
    path: LOGIN,
    auth: false,
    component: Login,
    isMenuItem: false,
}, {
    path: REDIRECT,
    auth: false,
    component: Redirect,
    isMenuItem: false,
}, {
    path: LOGOUT_REDIRECT,
    auth: false,
    component: LogoutRedirect,
    isMenuItem: false,
}];

export default config;
