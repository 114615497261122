/**
 * Require all in a dictionary
 */
const requireAll = (context, base) => {
    return context.keys().reduce((obj, key) => {
        const realPath = key.replace('./', '');
        const match = realPath.match(/^(.*)\.(.*)$/);
        const fileKey = match[1].replace('-', '').replace('/', '$');

        obj[fileKey] = require(`../${ base }/${ realPath }`).default;

        return obj;
    }, {});
};

export default requireAll;
