const common = {
    login: '登入',
    totalCount: '總數',
    totalPage: '總頁數',
    lastUpload: '最後更新',
    online: '上線',
    offline: '下線',
    take: '選擇',
    startTime: '開始時間',
    endTime: '結束時間',
    apply: '套用',
    clear: '清除',
    pageNumber: '頁碼',
    approved: '同意',
    approve: '同意',
    rejected: '駁回',
    reject: '駁回',
    cancel: '取消',
    submit: '送出',
    birthday: '生日',
    name: '姓名',
    country: '國家',
    save: 'Save',
    pleaseLogin: '請再次登入',
    pleaseLoginMessage: (
        '為了維護您的工作安全，連線階段已過期。' +
        '請重新整理瀏覽器, 或點擊登入'
    ),
    ok: '好',
    loginFailed: '登入失敗',
};

export default common;
