import PropTypes from 'prop-types';
import { Field } from 'formik';
import isValid from 'date-fns/isValid';
import DatePicker from '../DatePicker/DatePicker';

export default function DatePickerField(props) {
    return (
        <Field { ...props } type="select" component={ DatePickerAdapter } />
    );
}

function DatePickerAdapter({ field: { value, ...field }, ...props }) {
    const { onChange, onBlur, name } = field;
    const dateValue = new Date(value);

    const handleChange = date => {
        onChange({ target: { value: date?.toISOString(), name } });
    };

    const handleBlur = ({ target: { value } }) => {
        const date = new Date(value);

        onBlur({ target: {
            value: isValid(date) ? date.toISOString() : undefined,
            name
        } });
    };

    // do not show value if value is invalid
    return (
        <DatePicker
            { ...field }
            { ...props }
            selected={ isValid(dateValue) ? dateValue : undefined }
            onChange={ handleChange }
            onBlur={ handleBlur }
        />
    );
}

DatePickerAdapter.propTypes = {
    field: PropTypes.shape(),
};

DatePickerAdapter.defaultProps = {
    field: {},
};
