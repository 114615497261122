import React from 'react';
import { Flex } from '@chakra-ui/react';
import { css } from '@emotion/css';
import PropTypes from 'prop-types';
import {
    FILTER_TOWN,
    CITY_LIST,
    FILTER_TYPE,
    TYPE,
    TAIPEI,
} from '../../constants/city';
import MultiSelect, { SELECT_TYPE_SINGLE } from '../../components/Select/MultiSelect/MultiSelect';

const typeFilterClass = css`
    margin-left: 18px
`;

const typeOption = TYPE.map(({ text, value }) => ({
    text,
    value,
}));

const defaultTowns = [{
    text: '',
    value: ''
}];

const FilterBar = ({ onApply, settings, cityCode }) => {
    const townOptions = CITY_LIST[cityCode]?.map(item => ({
        text: item,
        value: item
    })) ?? defaultTowns;

    return (
        <Flex>
            <MultiSelect
                title="行政區"
                options={ townOptions }
                selected={ settings[FILTER_TOWN] }
                onApply={ onApply(FILTER_TOWN) }
            />
            { cityCode === TAIPEI && (
                <MultiSelect
                    title="許可狀態"
                    type={ SELECT_TYPE_SINGLE }
                    className={ typeFilterClass }
                    options={ typeOption }
                    selected={ settings[FILTER_TYPE] }
                    onApply={ onApply(FILTER_TYPE) }
                />
            ) }
        </Flex>
    );
};

FilterBar.propTypes = {
    onApply: PropTypes.func,
    settings: PropTypes.shape().isRequired,
    cityCode: PropTypes.number,
};

FilterBar.defaultProps = {
    onApply: () => {},
    cityCode: undefined
};

export default FilterBar;
