import React from 'react';
import PropTypes from 'prop-types';
import {
    Text as BaseText,
} from '@chakra-ui/react';

function Text(props) {
    const { children, value, onClick, ...rest } = props;

    return (
        <BaseText
            onClick={ onClick }
            { ...rest }
        >
            { children ?? value }
        </BaseText>
    );
}

Text.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onClick: PropTypes.func,
};

Text.defaultProps = {
    value: '',
    onClick: () => {}
};

export default Text;
