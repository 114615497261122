import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { signinCallback } from '../../auth/userManager';
import { CHANGED, UNCHANGED, useAuthContext } from '../../auth/useAuth';
import { BEFORE_LOGOUT_URL, ROOT } from '../../constants/routes';
import wait from '../../utils/wait';

function Redirect() {
    const history = useHistory();
    const { setSessionChanged } = useAuthContext();

    // we only hope `redirecting()` calls once,
    // but `history` and `setSessionChanged` cause `redirecting()` be call not only once.
    // above is a important reason to use `useRef` in order to memo `history` and `setSessionChanged` reference.
    const historyRef = useRef(history);
    const setSessionChangedRef = useRef(setSessionChanged);


    useEffect(() => {
        async function redirecting() {
            const history = historyRef.current;
            const setSessionChanged = setSessionChangedRef.current;
            try {
                const url = window.location.href;
                await signinCallback(url);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                // `window.top === window` means not silentSignin
                const isSigninRedirect = window.top === window;
                if (isSigninRedirect) {
                    const beforeUrl = sessionStorage.getItem(BEFORE_LOGOUT_URL);
                    sessionStorage.removeItem(BEFORE_LOGOUT_URL);
                    history.replace(beforeUrl ?? ROOT);
                    setSessionChanged(CHANGED);
                    await wait(3000);
                    setSessionChanged(UNCHANGED);
                }
            }
        }

        redirecting();
    }, []);

    return (
        <code>Redirecting...</code>
    );
}

export default Redirect;
