const Radio = {
    variants: {
        goshare({ theme }) {
            return {
                control: {
                    width: '20px',
                    height: '20px',
                    border: `2px solid ${ theme.colors['silver-grey'] }`,
                    transition: 'initial',
                    _checked: {
                        border: `2px solid ${ theme.colors['charcoal-grey'] }`,
                        background: 'charcoal-grey',
                        boxShadow: 'inset 0 0 0 4px #f9f9f9',
                        _focus: {
                            boxShadow: 'inset 0 0 0 4px #f9f9f9',
                        },
                        _before: {
                            backgroundColor: 'charcoal-grey',
                        },
                        _hover: {
                            background: 'charcoal-grey',
                            borderColor: 'charcoal-grey',
                        }
                    },
                },
                label: {
                    ...theme.textStyles.regular,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: 'charcoal-grey',
                }
            };
        }
    },
    defaultProps: {
        variant: 'goshare',
    }
};

export default Radio;
