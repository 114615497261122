import pointInPolygon from 'point-in-polygon';
import { NEW_TAIPEI } from '../constants/city';


const CITIES_SHOW_GSH_SERVICE_ZONE = [NEW_TAIPEI];

export const isAllowShowGSH = (cityCode) => (CITIES_SHOW_GSH_SERVICE_ZONE.indexOf(cityCode) !== -1);

export const calculateScooter = (list, filterTown = []) => {
    const scooterInTown = [];
    filterTown.forEach(({ geometry, properties }) => {
        list.forEach(item => {
            const { lat, lng } = item;
            if (pointInPolygon([ lng, lat ], geometry.coordinates[0])) {
                scooterInTown.push({ ...item, town: properties.T_Name });
            }
        });
    });
    return scooterInTown;
};
