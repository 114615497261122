import { transparentize } from '@chakra-ui/theme-tools';

export function baseStyle(theme) {
    return {
        field: {
            ...theme.textStyles.regular,
            lineHeight: 'normal',
            padding: '0px 12px',
            borderRadius: '0px',
            _placeholder: {
                color: transparentize('charcoal-grey', .5)(theme),
            },
            _invalid: {
                boxShadow: 'none',
                borderColor: 'strawberry',
                '&:hover': {
                    borderColor: 'strawberry',
                },
                '&:not(:focus), &:not(:hover)': {
                    borderColor: 'strawberry'
                },
            },
            '&:hover': {
                borderColor: 'very-light-pink',
                boxShadow: 'none',
            },
            '&:focus': {
                borderColor: '#0074ff',
                boxShadow: 'none',
            },
        }
    };
};

const parts = ['field', 'addon'];


const Input = {
    parts,
    variants: {
        outline({ theme, colorScheme }) {
            return {
                field: {
                    ...baseStyle(theme).field,
                    border: '2px solid',
                    borderColor: 'very-light-pink',
                    color: colorScheme,
                }
            };
        }
    },
    defaultProps: {
        variant: 'outline',
        colorScheme: 'charcoal-grey',
    }
};

export default Input;
