export const MATCH_TYPE_ALL = 'all';
export const MATCH_TYPE_ANY = 'any';

export const checkAuthedFeature = ({
    permission = [],
    matchType = MATCH_TYPE_ALL,
    featureList = [],
}) => {
    let isAuthed = false;

    if (permission.length) {
        switch (matchType) {
        case MATCH_TYPE_ANY:
            break;
        case MATCH_TYPE_ALL:
        default:
            let notMatchCounts = [];
            permission.forEach(item => {
                if (featureList.indexOf(item) < 0) {
                    notMatchCounts.push(item);
                }
            });
            isAuthed = notMatchCounts.length ? false : true;
            break;
        }
    }

    return isAuthed;
};
