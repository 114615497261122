import useSWR from 'swr';
import deepEqual from '../utils/deep-equal';

/**
 * Axios types
 * @typedef { import('swr').Key } Key
 * @typedef { import('swr').SWRConfiguration } SWRConfiguration
 * @typedef { import('swr').SWRResponse } SWRResponse
 **/

/**
 * @type { SWRConfiguration }
 */
const DEFAULT_OPTIONS = {
    revalidateOnFocus: false,
    refreshInterval: 0,
    shouldRetryOnError: false,
    compare(a, b) {
        const blank = {};
        const { __responseTime: t1, ...restA } = a ?? blank;
        const { __responseTime: t2, ...restB } = b ?? blank;

        return deepEqual(restA, restB);
    }
};

/**
 * @param { Key } key
 * @param { (...args: any) => Promise<any> } fetcher
 * @param { SWRConfiguration } options
 * @returns { SWRResponse }
 */
export default function useFetcher(key, fetcher, options = {}) {
    return useSWR(key, fetcher, {
        ...DEFAULT_OPTIONS,
        ...options,
    });
}
