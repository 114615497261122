import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DrawingManager } from '@react-google-maps/api';

const drawingManagerOptions = {
    drawingControlOptions: {
        position: 1,
        drawingModes: ['rectangle']
    },
    rectangleOptions: {
        fillColor: '#0069D2',
        fillOpacity: 0.3,
        strokeColor: '#0069D2',
        strokeWeight: 1,
        zIndex: 1,
    },
};

const ToolDrawingManager = ({ onOverlayComplete }) => {
    const [drawingManagerInstance, setDrawingManagerInstance] = useState(null);

    const onToolLoad = instance => {
        setDrawingManagerInstance(instance);
    };

    const handleOverlayComplete = e => {
        onOverlayComplete(drawingManagerInstance, e);
    };

    return (
        <DrawingManager
            id="test"
            onLoad={ onToolLoad }
            onOverlayComplete={ handleOverlayComplete }
            options={ drawingManagerOptions }
        />
    );
};

ToolDrawingManager.propTypes = {
    onOverlayComplete: PropTypes.func.isRequired,
};

ToolDrawingManager.defaultProps = {
};


export default ToolDrawingManager;
