import { ChakraProvider } from '@chakra-ui/react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStateProvider from './components/GlobalStateProvider/GlobalStateProvider';
import Routes from './components/Routes/Routes';
import theme from './styles/theme/theme';
import Fonts from './components/Fonts/Fonts';
import i18next from './utils/i18next';

function App() {
    return (
        <ChakraProvider theme={ theme } resetCSS>
            <Fonts />
            <I18nextProvider i18n={ i18next }>
                <Router>
                    <GlobalStateProvider>
                        <Routes />
                    </GlobalStateProvider>
                </Router>
            </I18nextProvider>
        </ChakraProvider>
    );
}

export default App;
