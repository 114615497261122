
function headingBaseStyle(theme) {
    return {
        ...theme.textStyles.bold,
    };
}

const Heading = {
    baseStyle: ({ theme }) => ({
        ...headingBaseStyle(theme)
    }),
    variants: {
        small({ theme }) {
            return {
                ...theme.textStyles.regular,
            };
        }
    }

};

export default Heading;
