function baseStyle(theme) {
    return {
        ...theme.textStyles.regular,
        borderRadius: '16px',
        backgroundColor: 'charcoal-grey',
        fontSize: '13px',
        color: 'white',
        padding: '6px 12px',
    };
};

const Tooltip = {
    variants: {
        goshare({ theme }) {
            return {
                ...baseStyle(theme),
            };
        },
    },
    defaultProps: {
        variant: 'goshare',
    },
};

export default Tooltip;
