import { useState, useEffect } from 'react';

const defaultSettings = {
    enableHighAccuracy: false,
    timeout: Infinity,
    maximumAge: 0,
};

const usePosition = (settings = defaultSettings) => {
    const [position, setPosition] = useState({});
    const [error, setError] = useState(null);

    const onChange = ({ coords, timestamp }) => {
        const latitude = parseFloat(coords.latitude.toFixed(6));
        const longitude = parseFloat(coords.longitude.toFixed(6));
        setPosition({
            latitude,
            longitude,
            accuracy: coords.accuracy,
            timestamp,
        });
    };

    const onError = (error) => {
        setError(error.message);
    };

    useEffect(() => {
        if (!navigator || !navigator.geolocation) {
            setError('Geolocation is not supported');
            return;
        }

        let watcher = null;
        watcher = navigator.geolocation.watchPosition(onChange, onError, settings);

        return () => watcher && navigator.geolocation.clearWatch(watcher);
    }, [settings]);

    return { ...position, error };
};

export default usePosition;