import PropTypes from 'prop-types';
import { Field } from 'formik';
import Checkbox from './Checkbox';

export default function CheckboxField(props) {
    return (
        <Field { ...props } type="checkbox" component={ CheckboxAdapter } />
    );
}

function CheckboxAdapter({ field, value, type, ...props }) {
    const { onChange, onBlur, name } = field;

    const handleChange = (e) => {
        const { checked } = e.target;
        onChange({ target: { checked, value, name, type } });
    };

    const handleBlur = (e) => {
        const { checked } = e.target;
        onBlur({ target: { checked, value, name, type } });
    };

    return (
        <Checkbox
            { ...field }
            { ...props }
            onChange={ handleChange }
            onBlur={ handleBlur }
        />
    );
}

CheckboxAdapter.propTypes = {
    field: PropTypes.shape(),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    type: PropTypes.string,
};

CheckboxAdapter.defaultProps = {
    field: {},
    value: undefined,
    type: 'checkbox',
};
