import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text, Input, Flex } from '@chakra-ui/react';
import { PLATE } from '../../constants/city';

const SearchBar = ({ onApply }) => {
    const [searchValue, setSearchValue] = useState();

    const handleKeyDown = e => {
        // enter press
        if (e.keyCode === 13) {
            e.preventDefault();
            onApply(PLATE)(searchValue ? searchValue : undefined);
        }
    };

    const handleChange = e => {
        const value = e.target.value;
        setSearchValue(value);
    };

    return (
        <Flex
            alignItems="center"
            marginRight={ ['12px', '12px', 0] }
            width={ ['100%', '100%', 'auto'] }
            marginBottom={ [ '12px', '12px', '12px', 0 ] }
        >
            <Text colorScheme="blackAlpha">
                搜尋:
            </Text>
            <Input
                width={ ['auto', '287px', '287px'] }
                placeholder="Search"
                onKeyDown={ handleKeyDown }
                onChange={ handleChange }
                value={ searchValue ?? '' }
                marginX="12px"
                marginRight={ [0, 0, '12px'] }
                flexGrow="1"
            />
        </Flex>
    );
};

SearchBar.propTypes = {
    onApply: PropTypes.func,
};

SearchBar.defaultProps = {
    onApply: () => {},
};

export default SearchBar;
