/**
 * Perference handler - to save & get preferences
 */
const ITEM_KEY = 'user-preferences';
const preference = {
    set: (name, payload) => {
        const currPerferences = preference.get();
        const mergedPayload = {
            ...currPerferences,
            [name]: payload,
        };

        localStorage.setItem(ITEM_KEY, JSON.stringify(mergedPayload));
    },

    get: name => {
        const paylaod = JSON.parse(localStorage.getItem(ITEM_KEY)) || {};

        return (name ? paylaod[name] : paylaod);
    },
};

export default preference;
