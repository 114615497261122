import { Flex, Text, chakra } from '@chakra-ui/react';
import ProfilePopover from './ProfilePopover';


export default function Header() {
    return (
        <Flex
            as="header"
            px={ ['14px', '30px'] }
            py="18px"
            flexBasis={ ['48px'] }
            flexShrink="0"
            backgroundColor="#f9f9f9"
            alignItems="center"
            justifyContent="space-between"
        >
            <Text
                fontSize={ ['16px', '24px'] }
                color="#173e6f"
                marginRight="auto"
            >
                <chakra.span textStyle="bold" marginRight="8px">GOSHARE</chakra.span>
                <chakra.span textStyle="medium">共享運具資訊平台</chakra.span>
            </Text>
            <ProfilePopover />
        </Flex>
    );
}
