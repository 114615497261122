import { Suspense, memo } from 'react';
import { Switch, Route as BaseRoute } from 'react-router-dom';
import PageNotFound from '../../pages/PageNotFound/PageNotFound';
import LoadingPage from '../../components/LoadingPage';
import routeConfig from './config';
import PrivatedRoute from './PrivatedRoute';

const dynamic = Component => props => (
    <Suspense fallback={ <LoadingPage /> }>
        <Component { ...props } />
    </Suspense>
);

const config = routeConfig.map(singleConfig => ({
    ...singleConfig,
    component: dynamic(memo(singleConfig.component))
}));

export default function Routes() {
    return (
        <Switch>
            { config.map(({ path, auth, component }) => {
                const Route = auth ? PrivatedRoute : BaseRoute;
                return (
                    <Route
                        exact
                        key={ path }
                        path={ path }
                        auth={ auth }
                        component={ component }
                    />
                );
            }) }
            <BaseRoute component={ PageNotFound } />
        </Switch>
    );
}
