import PropTypes from 'prop-types';
import { Field } from 'formik';
import Select from './Select';

export default function SelectField(props) {
    return (
        <Field { ...props } type="select" component={ SelectAdapter } />
    );
}

function SelectAdapter({ field, ...props }) {
    const { onChange, onBlur, name, value } = field;

    const handleChange = ({ value }) => {
        onChange({ target: { value, name } });
    };

    const handleBlur = value => {
        onBlur({ target: { value, name } });
    };

    return (
        <Select
            { ...field }
            { ...props }
            selected={ value }
            onChange={ handleChange }
            onBlur={ handleBlur }
        />
    );
}

SelectAdapter.propTypes = {
    field: PropTypes.shape(),
};

SelectAdapter.defaultProps = {
    field: {},
};
