import { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { chakra, Box, SimpleGrid } from '@chakra-ui/react';
import { OverlayView } from '@react-google-maps/api';
import { OFFLINE, IDLE, RENTING } from '../../../constants/city';
import { subHours } from 'date-fns';

const InfoPanel = forwardRef(({ plate, type, socLevel, position, state, idleTimeInfo }, ref) => {
    const [idleTime, setIdleTime] = useState();

    let statusString;

    switch (state) {
    case OFFLINE:
        statusString = '下線';
        break;
    case IDLE:
        statusString = '可租借';
        break;
    case RENTING:
        statusString = '租借中';
        break;
    default:
        break;
    }

    useEffect(() => {
        async function calcIdleTime() {
            if (!idleTimeInfo) {
                return;
            }

            const [
                { default: intervalToDuration },
                { default: formatDuration },
                { default: zhTW }
            ] = await Promise.all([
                import('date-fns/intervalToDuration'),
                import('date-fns/formatDuration'),
                import('date-fns/locale/zh-TW'),
                import('date-fns/subHours'),
            ]);
            const duration = intervalToDuration({
                start: subHours(new Date(), idleTimeInfo),
                end: new Date()
            });
            const idleTime = formatDuration(duration, {
                format: ['years', 'months', 'days', 'hours', 'minutes'],
                locale: zhTW,
            });

            setIdleTime(idleTime);
        }

        calcIdleTime();
    }, [idleTimeInfo]);

    return (
        <OverlayView
            position={ position }
            mapPaneName={ OverlayView.OVERLAY_MOUSE_TARGET }
        >
            <SimpleGrid
                ref={ ref }
                as="menu"
                columns={ 2 }
                gridTemplateColumns="min-content min-content"
                rowGap="1px"
                position="absolute"
                transform="translate(-50%, calc(-100% - 35px))"
                border="2px solid"
                borderColor="#fff"
                backgroundColor="#323237"
                color="white"
                whiteSpace="nowrap"
                borderRadius="12px"
                padding="10px 22px"
                fontSize="14px"
                textStyle="medium"
                zIndex={ 20001 }
            >
                <InfoItem>車牌</InfoItem>
                <InfoItem>{ plate }</InfoItem>
                <InfoItem>車種</InfoItem>
                <InfoItem>{ type }</InfoItem>
                <InfoItem>狀態</InfoItem>
                <InfoItem>{ statusString }</InfoItem>
                <InfoItem>電量</InfoItem>
                <InfoItem><SocLevel socLevel={ socLevel } /></InfoItem>
                <InfoItem>閒置時間</InfoItem>
                <InfoItem>{ idleTime ?? '0 小時' }</InfoItem>
            </SimpleGrid>
        </OverlayView>
    );
});

export default InfoPanel;

InfoPanel.propTypes = {
    plate: PropTypes.string,
    type: PropTypes.string,
    socLevel: PropTypes.number,
    idleTimeInfo: PropTypes.number,
    position: PropTypes.shape(),
    state: PropTypes.number,
};

InfoPanel.defaultProps = {
    plate: '',
    type: '',
    socLevel: 0,
    idleTimeInfo: 0,
    position: {},
    state: undefined,
};

const InfoItem = ({ children }) => {
    return (
        <Box
            display="flex"
            paddingY="8px"
            __css={ {
                '&:nth-of-type(2n + 1)': {
                    paddingRight: '18px',
                },
                '&:nth-of-type(2n)': {
                    justifySelf: 'stretch',
                    justifyContent: 'flex-end',
                },
                '&:nth-of-type(-n + 8)': {
                    borderBottom: '1px solid white'
                }
            } }
        >
            { children }
        </Box>
    );
};

const SocLevel = ({ socLevel }) => {

    return (
        <chakra.span letterSpacing="-4px">
            <chakra.span opacity={ socLevel < 5 ? 0.3 : 1 }>▌</chakra.span>
            <chakra.span opacity={ socLevel < 4 ? 0.3 : 1 }>▌</chakra.span>
            <chakra.span opacity={ socLevel < 3 ? 0.3 : 1 }>▌</chakra.span>
            <chakra.span opacity={ socLevel < 2 ? 0.3 : 1 }>▌</chakra.span>
            <chakra.span opacity={ socLevel < 1 ? 0.3 : 1 }>▌</chakra.span>
        </chakra.span>
    );
};

SocLevel.propTypes = {
    socLevel: PropTypes.number.isRequired,
};
