import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { signoutCallback } from '../../auth/userManager';
import { CHANGED, UNCHANGED, useAuthContext } from '../../auth/useAuth';
import { LOGIN } from '../../constants/routes';
import wait from '../../utils/wait';

function LogoutRedirect() {
    const history = useHistory();
    const { setSessionChanged } = useAuthContext();

    // we only hope `redirecting()` calls once,
    // but `history` and `setSessionChanged` cause `redirecting()` not only once.
    // it's a important reason to use `useRef` in order to memo `history` and `setSessionChanged` reference.
    const historyRef = useRef(history);
    const setSessionChangedRef = useRef(setSessionChanged);

    useEffect(() => {
        async function logoutRedirecting() {
            const history = historyRef.current;
            const setSessionChanged = setSessionChangedRef.current;
            const url = window.location.href;
            await signoutCallback(url);
            history.replace(LOGIN);
            setSessionChanged(CHANGED);
            await wait(3000);
            setSessionChanged(UNCHANGED);
        }

        logoutRedirecting();
    }, []);

    return (
        <code>Logout redirecting...</code>
    );
}

export default LogoutRedirect;
