import { createContext, useContext } from 'react';
import useSigninSilent, {
    INIT,
    AUTHED,
    UNAUTHED,
    mutateSigninSilent,
    updatingAuthPromise
} from './useSigninSilent';
import useSessionMonitor, { CHANGED, UNCHANGED } from './useSessionMonitor';

/**
 * @typedef { {
 *   data?: { access_token: string, token_type: string },
 *   error?: { error?: string },
 *   authStatus: INIT | AUTHED | UNAUTHED,
 *   setSessionChanged: React.Dispatch<React.SetStateAction<string>>
 * } } Auth
 */

/**
 * @return { Auth }
**/
export default function useAuth() {
    const { data, error, authStatus } = useSigninSilent();
    const { setSessionChanged } = useSessionMonitor();

    return { data, error, authStatus, setSessionChanged };
}

/**
 * @type { React.Context<Auth> }
 */
const AuthContext = createContext({
    data: undefined,
    error: undefined,
    authStatus: INIT,
    setSessionChanged: () => {},
});

const AuthProvider = AuthContext.Provider;

const useAuthContext = () => useContext(AuthContext);

export {
    INIT,
    AUTHED,
    UNAUTHED,
    CHANGED,
    UNCHANGED,
    mutateSigninSilent,
    updatingAuthPromise,
    AuthProvider,
    useAuthContext,
};
