import { transparentize } from '@chakra-ui/theme-tools';

const Link = {
    variants: {
        primary() {
            return {
                color: 'deep-sky-blue',
            };
        },
        nav({ theme }) {
            return {
                ...theme.textStyles.medium,
                padding: '0px 40px',
                lineHeight: '53px',
                fobtSize: '18px',
                color: theme.colors['silver-grey'],
                _hover: {
                    backgroundColor: transparentize('#ffffff', .05)(theme),
                    textDecoration: 'none',
                },
                '&.active': {
                    color: '#ffffff',
                    backgroundColor: transparentize('#ffffff', .05)(theme)
                },
                '&:focus': {
                    boxShadow: 'none'
                }
            };
        },
    },
    defaultProps: {
        variant: 'primary',
    }
};

export default Link;
