import { useState, useEffect } from 'react';
import { mutate } from 'swr';
import { useRouteMatch } from 'react-router-dom';
import { signinSilent } from './userManager';
import useFetcher from '../hooks/useFetcher';
import {
    ROOT,
    LOGIN,
} from '../constants/routes';
import { __DEV__, isEmpty } from '../utils/assertion';

const SIGNIN_SILENT_KEY = 'SIGNIN_SILENT_KEY';
const USE_SIGNIN_SILENT_WHITELIST = [
    ROOT,
    LOGIN,
];

// all auth status
export const INIT = 'INIT';
export const AUTHED = 'AUTHED';
export const UNAUTHED = 'UNAUTHED';

/**
 * @param { boolean } [shouldRevalidate]
 * @param { { access_token: string, token_type: string } | () => {} } [data]
 * @return { Promise<{ access_token: string, token_type: string }> }
**/
export function mutateSigninSilent(data, shouldRevalidate) {
    return mutate(SIGNIN_SILENT_KEY, data, shouldRevalidate);
}

let authStatus;

export default function useSigninSilent() {
    const paused = !useRouteMatch({
        path: USE_SIGNIN_SILENT_WHITELIST,
        exact: true,
    });
    const [stopRevalidate, setStopRevalidate] = useState(false);
    const { data, error } = useFetcher(SIGNIN_SILENT_KEY, signinSilent, {
        revalidateOnFocus: !stopRevalidate,
        isPaused: () => paused || __DEV__,
    });

    authStatus = undefined;

    if (!data && !error) {
        authStatus = INIT;
    }

    if (data && !error) {
        authStatus = AUTHED;
    }

    if (error) {
        // error.error is error_code from auth server
        if (!error.error) {
            // unexpected error
            console.error('unexpected error: ', error);
        }

        authStatus = UNAUTHED;
    }

    useEffect(() => {
        if (!stopRevalidate && authStatus === AUTHED) {
            setStopRevalidate(true);
        }

        if (stopRevalidate && authStatus !== AUTHED) {
            setStopRevalidate(false);
        }
    }, [stopRevalidate, data, error]);

    if (!authStatus) {
        console.error('unrecognized auth status');
    }

    if (authStatus === AUTHED || authStatus === UNAUTHED) {
        while (!isEmpty(pendingRequestResolve)) {
            const resolve = pendingRequestResolve.shift();
            resolve();
        }
    }

    return { data, error, authStatus };
}

const pendingRequestResolve = [];

export function updatingAuthPromise() {
    return new Promise(resolve => {
        if (__DEV__) {
            resolve();
            return;
        }

        if (authStatus === INIT) {
            pendingRequestResolve.push(resolve);
        }

        if (authStatus === AUTHED || authStatus === UNAUTHED) {
            resolve();
        }
    });
}
