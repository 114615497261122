import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import mapOptions from './gmap-option';
import { isNumber } from '../../utils/assertion';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import {
    CITY_TOWN,
    CITY_CENTER,
} from '../../constants/city';
import CurrentLocation from './Toolbox/CurrentLocation';
import CurrentMarker from './Markers/CurrentMarker';
import ScooterCluster from './Markers/ScooterCluster';
import usePosition from '../../hooks/usePosition';
import usePrevious from '../../hooks/usePrevious';
import ToolDrawingManager from './Toolbox/ToolDrawingManager';
import { useMap } from '../../contexts/MapContext';
import { css } from '@emotion/css';
import deepEqual from '../../utils/deep-equal';
import { polygon } from 'polygon-tools';
import { isAllowShowGSH } from '../../helpers/map';

const styleZooomControlPos = css`
    overflow: hidden;
    .gmnoprint.gm-bundled-control {

        left: 3px!important;
        top: 27px!important;
        @media screen and (min-width: 80em) {
            left: -1px!important;
            top: 24px!important;
        }
    }
`;

const containerStyle = {
    width: '100vw',
    height: '90vh'
};

const getPosition = ({ latitude, longitude }) => {
    const lat = isNumber(latitude) ? latitude : 25.0123;
    const lng = isNumber(longitude) ? longitude : 121.4655;
    return { lat, lng };
};

const libraries = ['drawing'];

const {
    REACT_APP_GOOGLE_MAP_API_ID: MAP_ID,
    REACT_APP_GOOGLE_MAP_API_KEY: MAP_KEY,
} = process.env;

const Map = ({
    selectZone,
    zoom,
    list,
    onScooterClick,
    onSelectZone,
    onScooterOverlay,
    cityCode,
    children,
    displayGSH,
    zoneData
}) => {
    const center = CITY_CENTER[cityCode];
    const { latitude, longitude } = usePosition();
    const [currentPosition, setCurrentPosition] = useState();
    const [showPosition, setShowPosition] = useState(false);
    const [currentZoom, setCurrentZoom] = useState(zoom);
    const [scooterList, setScooterList] = useState();
    const { isLoaded } = useJsApiLoader({
        id: MAP_ID,
        googleMapsApiKey: MAP_KEY,
        libraries: libraries
    });

    const [mapObject, setMapObject] = useState(null);
    const prevList = usePrevious(list);
    const { state: scooterState, dispatch: scooterDispatch } = useMap();


    const handleSwitchServiceZone = useCallback(mapObject => {
        mapObject.data.forEach(feature => {
            const name = feature.getProperty('name');
            if (name === 'GSH') {
                mapObject.data.overrideStyle(feature, {
                    strokeColor: 'transparent',
                    strokeWidth: 0,
                    fillColor: displayGSH ? '#0074FF' : 'transparent',
                    fillOpacity: 0.25
                });
            }
        });
    }, [displayGSH]);

    useEffect(() => {
        setCurrentPosition(getPosition({ latitude, longitude }));
    }, [latitude, longitude]);

    useEffect(() => {
        if (cityCode) {
            const filterTown = CITY_TOWN[cityCode]?.features ?? [];
            scooterDispatch({ data: { list, filterTown } });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityCode]);

    useEffect(() => {
        if (!deepEqual(prevList, list)) {
            const filterTown = CITY_TOWN[cityCode]?.features ?? [];
            scooterDispatch({ data: { list, filterTown } });
        }
    }, [prevList, list, scooterDispatch, cityCode]);

    useEffect(() => {
        const { mergeScooterList } = scooterState;
        setScooterList(mergeScooterList);
    }, [scooterState]);

    useEffect(() => {
        if (mapObject && isAllowShowGSH(cityCode)) {
            handleSwitchServiceZone(mapObject);
        }
    }, [displayGSH, mapObject, handleSwitchServiceZone, cityCode]);


    useEffect(() => {
        if (mapObject) {
            mapObject.data.forEach((feature) => {
                mapObject.data.remove(feature);
            });
            if (selectZone.length !== 0) {
                const filterTown = CITY_TOWN[cityCode]
                    .features.filter(item => selectZone.includes(item.properties.T_Name));
                handleZone(mapObject, {
                    type: 'FeatureCollection',
                    features: filterTown
                });
                scooterDispatch({ data: { list, filterTown } });
            }
            else {
                handleZone(mapObject, CITY_TOWN[cityCode]);
            }
            if (isAllowShowGSH(cityCode)) {
                handleSwitchServiceZone(mapObject);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectZone, mapObject, list, scooterDispatch]);

    const handleGSHServiceZone = () => {
        let serviceZone = [];
        let dotServiceZone = [];
        let nonServiceZone = [];
        let defaultZone = [];
        const { data } = zoneData;
        serviceZone = data?.reduce((boundaries, current) => {
            const { type, service_type } = current;
            if (type === 1 && service_type !== 1) {
                let polygon = [...boundaries];
                polygon = [...polygon, current.boundary];
                return polygon;
            }
            return [...boundaries];
        }, []);
        dotServiceZone = data?.reduce((boundaries, current) => {
            const { type, service_type } = current;
            if (type === 1 && service_type === 1) {
                let polygon = [...boundaries];
                polygon = [...polygon, current.boundary];
                return polygon;
            }
            return [...boundaries];
        }, []);
        nonServiceZone = data?.reduce((boundaries, current) => {
            const { type } = current;
            if (type === 0) {
                let polygon = [...boundaries];
                polygon = [...polygon, current.boundary];
                return polygon;
            }
            return [...boundaries];
        }, []);
        defaultZone = data?.reduce((boundaries, current) => {
            const { type } = current;
            if (type <= 1) {
                let polygon = [...boundaries];
                polygon = [...polygon, current.boundary];
                return polygon;
            }
            return [...boundaries];
        }, []);
        return { defaultZone, serviceZone, dotServiceZone, nonServiceZone };
    };

    const handleZone = (map, geoJSON = { type: 'FeatureCollection', features: [] }, style = {}) => {
        let outerZone = [];
        if (isAllowShowGSH(cityCode) && zoneData) {
            const { serviceZone: gshServiceZone, dotServiceZone, nonServiceZone } = handleGSHServiceZone();
            gshServiceZone?.forEach((item, i) => {
                let interCount = 0;
                nonServiceZone?.forEach(ele => {
                    let intersectionZone = polygon.intersection(item, ele);
                    if (intersectionZone.length !== 0) {
                        intersectionZone = [...intersectionZone[0], intersectionZone[0][0]];
                        if (outerZone.length > 0) {
                            interCount += 1;
                            if (outerZone.slice(-1)[0][0].length === gshServiceZone[i].length) {
                                outerZone.slice(-1)[0].splice(1, 0, intersectionZone);
                            }
                            else {
                                outerZone = [...outerZone, [gshServiceZone[i], intersectionZone]];
                            }
                        }
                        else {
                            outerZone = [...outerZone, [gshServiceZone[i], intersectionZone]];
                        }
                    }
                });
                if (!interCount) {
                    outerZone = [...outerZone, [item]];
                }
            });
            dotServiceZone?.forEach((item) => {
                outerZone = [...outerZone, [item]];
            });
        }
        const worldBoundary = [
            [180, -90],
            [0, -90],
            [-180, -90],
            [-180, 90],
            [0, 90],
            [180, 90],
            [180, -90]
        ];
        let zones = geoJSON.features.reduce((boundaries, zone) => {
            if (zone.geometry.type === 'MultiPolygon') {
                let polygon = [...boundaries];
                for (let i = 0; i < zone.geometry.coordinates.length; i += 1) {
                    polygon = [...polygon, zone.geometry.coordinates[i][0]];
                }
                return (polygon);
            }
            return [...boundaries, zone.geometry.coordinates[0]];
        }, []);

        let features = [];
        if (zones.length !== 0) {
            features = [...features, {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [
                        worldBoundary,
                        ...zones,
                    ],
                },
                properties: {
                    name: 'zones',
                }
            }, {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: zones,
                },
                properties: {
                    name: 'border',
                }
            }, {
                type: 'Feature',
                geometry: {
                    type: 'MultiPolygon',
                    coordinates: outerZone,
                },
                properties: {
                    name: 'GSH',
                }
            }];
        }
        map.data.addGeoJson({
            type: 'FeatureCollection',
            features
        });
        map.data.setStyle(feature => {
            const name = feature.getProperty('name');
            let layerStyle = {
                strokeColor: 'transparent',
                strokeWidth: 0,
                fillColor: 'transparent',
            };
            switch (name) {
            case 'zones':
                layerStyle = {
                    strokeColor: 'transparent',
                    strokeWidth: 0,
                    fillColor: '#6c6c6c',
                    fillOpacity: 0.5,
                };
                break;
            case 'border':
                layerStyle = {
                    strokeColor: '#6FEFF7',
                    strokeWidth: 3,
                    fillColor: 'transparent',
                };
                break;
            case 'GSH':
                layerStyle =  {
                    strokeColor: 'transparent',
                    strokeWidth: 0,
                    fillColor: 'transparent',
                    fillOpacity: 0,
                };
                break;
            default:
                break;
            }
            return layerStyle;
        });
        onSelectZone();
    };



    const onLoad = useCallback((map) => {
        const { maps } = window.google;
        const options = {
            zoomControlOptions: {
                position: maps.ControlPosition.LEFT_TOP
            }
        };
        map.setOptions(options);
        setMapObject(map);
    }, []);

    const onUnmount = useCallback(() => {
        setMapObject(null);
    }, []);

    const onZoomChanged = useCallback(() => {
        if (mapObject) {
            const zoom = mapObject.getZoom();
            setCurrentZoom(zoom);
        }

    }, [mapObject]);

    const handlePositionClick = () => {
        if (latitude && longitude) {
            setShowPosition(true);
            mapObject.panTo(currentPosition);
            mapObject.setZoom(15);
        }
    };

    const handleOverlayComplete = (drawingManagerInstance, e) => {
        const rectangle = e.overlay;
        const selectedScooters = scooterList.filter(({ lat, lng }) => {
            const isContained = rectangle.getBounds().contains({ lat, lng });
            return isContained;
        }).map(({ scooter_id, state }) => ({ id: scooter_id, state }));
        onScooterOverlay(selectedScooters);

        // clear
        drawingManagerInstance.setDrawingMode(null);
        rectangle.setMap(null);
    };

    return (
        <>
            {
                isLoaded ? (
                    <div className={ styleZooomControlPos }>
                        <GoogleMap
                            mapContainerStyle={ containerStyle }
                            libraries={ ['drawing'] }
                            center={ center }
                            zoom={ zoom }
                            options={ mapOptions }
                            onLoad={ onLoad }
                            onUnmount={ onUnmount }
                            onZoomChanged={ onZoomChanged }
                        >
                            { /* Child components, such as markers, info windows, etc. */ }
                            { showPosition && (
                                <CurrentMarker
                                    lat={ latitude }
                                    lng={ longitude }
                                />
                            ) }
                            <ScooterCluster
                                list={ scooterList }
                                currentZoom={ currentZoom }
                                onScooterClick={ onScooterClick }
                                cityCode={ cityCode }
                            />
                            <ToolDrawingManager
                                onOverlayComplete={ handleOverlayComplete }
                            />
                            { children }
                        </GoogleMap>
                        {
                            latitude && (
                                <CurrentLocation
                                    disabled={ latitude === undefined }
                                    onPositionClick={ handlePositionClick }
                                />
                            )
                        }
                    </div>
                ) : null
            }
        </>
    );
};

Map.propTypes = {
    zoom: PropTypes.number,
    center: PropTypes.shape({}),
    selectZone: PropTypes.arrayOf(PropTypes.string),
    list: PropTypes.arrayOf(PropTypes.shape({
        scooter_id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        state: PropTypes.number,
        lat: PropTypes.number,
        lng: PropTypes.number,
    })),
    onScooterClick: PropTypes.func,
    onScooterOverlay: PropTypes.func,
    onSelectZone: PropTypes.func,
    cityCode: PropTypes.number,
    displayGSH: PropTypes.bool,
    zoneData: PropTypes.shape({
        data: PropTypes.arrayOf(
            PropTypes.shape({})
        )
    }),
};

Map.defaultProps = {
    zoom: 10,
    center: { lat: 25.0375, lng: 121.5638 },
    selectZone: [],
    list: [],
    onScooterClick: () => {},
    onScooterOverlay: () => {},
    onSelectZone: () => {},
    cityCode: undefined,
    displayGSH: false,
    zoneData: undefined,
};



export default Map;
