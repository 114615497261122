import { useEffect, useRef } from 'react';
import BaseDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { useTheme, } from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import StyledDatePicker from './StyledDatePicker';
import Text from '../Text/Text';
import usePrevious from '../../hooks/usePrevious';

const timelistSelector = '.react-datepicker__time-list';
const selectedTimeSelector = '.react-datepicker__time-list-item--selected';

function DatePicker({ title, selected, ...props }) {
    const theme = useTheme();
    const styleDatePickerRef = useRef();

    const prevSelected = usePrevious(selected);
    useEffect(() => {
        if (!prevSelected && selected) {
            const timeList = styleDatePickerRef.current?.querySelector(timelistSelector);
            const selectedTime = timeList?.querySelector(selectedTimeSelector);

            if (timeList && selectedTime) {
                timeList.scrollTop = selectedTime.offsetTop;
            }
        }
    }, [prevSelected, selected]);

    return (
        <StyledDatePicker ref={ styleDatePickerRef } theme={ theme }>
            <Text variant="value" value={ title } marginBottom="4px" />
            <BaseDatePicker selected={ selected } { ...props } />
        </StyledDatePicker>
    );
}

DatePicker.propTypes = {
    title: PropTypes.string,
    selected: PropTypes.shape(Date)
};

DatePicker.defaultProps = {
    title: undefined,
    selected: undefined,
};

export default DatePicker;
