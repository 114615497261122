const textStyles = {
    regular: {
        fontFamily: 'TTNormsPro-Regular, sans-serif, PingFangTC-Regular, "Microsoft JhengHei"',
        fontWeight: 'normal',
    },
    medium: {
        fontFamily: 'TTNormsPro-Medium, sans-serif, PingFangSC-Medium, "Microsoft JhengHei"',
        fontWeight: 500,
    },
    bold: {
        fontFamily: 'TTNormsPro-Bold, sans-serif, PingFangTC-Semibold, "Microsoft JhengHei"',
        fontWeight: 'bold',
    }
};

export default textStyles;
