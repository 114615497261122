const Select = {
    variants: {
        primary({ theme }) {
            return {
                field: {
                    ...theme.textStyles.regular,
                    width: '100%',
                    borderRadius: '20px',
                    px: '16px',
                    py: '12px',
                    color: 'charcoal-grey',
                    paddingRight: '52px',
                    h: 'auto',
                    fontSize: '14px',
                    lineHeight: '1.15',
                    _invalid: {
                        border: '2px solid',
                        borderColor: 'strawberry',
                    },
                },
                icon: {
                    fontSize: 'sm',
                    right: '16px',
                    width: '16px',
                }
            };
        }
    },
    defaultProps: {
        variant: 'primary',
    },
};
export default Select;
