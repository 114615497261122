import styled from '@emotion/styled';

const StyledDatePicker = styled.div(props => {
    const { theme } = props;
    const deepSkyBlue = theme.colors['deep-sky-blue'];
    const fontStyle = theme.textStyles.regular;

    return {
        '& .react-datepicker': {
            ...fontStyle,
            color: '#323237',
            border: 'solid 1px #f5f5f5',
            borderRadius: '5px',
        },
        '& .react-datepicker__time-container': {
            borderLeft: 'solid 1px #f5f5f5'
        },
        '& .react-datepicker__header': {
            borderBottom: 'solid 1px #f5f5f5',
            borderTopLeftRadius: '4px',
        },
        '& .react-datepicker__header:not(.react-datepicker__header--has-time-select)': {
            borderTopRightRadius: '4px',
        },
        '& .react-datepicker__day--selected': {
            backgroundColor: deepSkyBlue,
            color: 'white',
            borderRadius: '50%',
            '&:focus': {
                outline: 'none'
            }
        },
        '& .react-datepicker__day--in-range.react-datepicker__day--selected': {
            backgroundColor: deepSkyBlue,
            color: 'white',
            borderRadius: '50%',
            '&:hover': {
                backgroundColor: deepSkyBlue,
                color: 'white',
            },
            '&:focus': {
                outline: 'none'
            }
        },
        '& .react-datepicker__day--in-range': {
            backgroundColor: 'white',
            color: '#323237',
            borderRadius: '50%',
            '&:hover': {
                backgroundColor: 'rgba(2, 117, 255, 0.1)',
                color: 'white',
            },
            '&:focus': {
                outline: 'none'
            }
        },
        '& .react-datepicker__day': {
            width: '27px',
            height: '27px',
            '&:hover': {
                backgroundColor: deepSkyBlue,
                borderRadius: '50%',
                color: 'white',
            },
            '&:focus': {
                outline: 'none'
            }
        },
        '& .react-datepicker__day--today': {
            border: 'solid 1px',
            borderColor: deepSkyBlue,
            borderRadius: '50%'
        },
        '& .react-datepicker__day--disabled': {
            '&:hover': {
                backgroundColor: 'transparent',
                color: '#ccc',
                '&.react-datepicker__day--selected': {
                    backgroundColor: deepSkyBlue,
                    color: 'white',
                }
            },
            '&:focus': {
                outline: 'none'
            }
        },
        '& .react-datepicker__day--in-selecting-range': {
            color: '#323237',
            backgroundColor: 'white',
            borderRadius: '50%',
            '&:hover': {
                color: 'white',
                backgroundColor: deepSkyBlue,
                borderRadius: '50%',
            }
        },
        [`& .react-datepicker__time-container .react-datepicker__time
            .react-datepicker__time-box ul.react-datepicker__time-list
            li.react-datepicker__time-list-item`]: {
            outline: 'none',
        },
        [`& .react-datepicker__time-container .react-datepicker__time
            .react-datepicker__time-box ul.react-datepicker__time-list
            li.react-datepicker__time-list-item--selected`]: {
            backgroundColor: deepSkyBlue,
            '&:hover': {
                backgroundColor: deepSkyBlue,
            }
        }
    };
});

export default StyledDatePicker;
