import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getTranslations, namespaces } from '../locale';

const languageDetector = new LanguageDetector(null, {
    order: ['localStorage', 'navigator', 'htmlTag'],
    lookupLocalStorage: 'lang',
});

i18next
    .use(languageDetector)
    .init({
        debug: false,
        // React already does escaping
        interpolation: { escapeValue: false },
        ns: namespaces,
        defaultNS: 'common',
        nsSeparator: '.',
        resources: getTranslations(),
        fallbackLng: 'en-US',
    });

export default i18next;
