const colors = {
    'deep-sky-blue': '#0275ff',
    'very-light-pink': '#d7d7d7',
    'very-light-pink-two': '#f3f3f3',
    'charcoal-grey': '#323237',
    'strawberry': '#ff1923',
    'shamrock': '#00b946',
    'bluish-grey': '#7c8c8f',
    'bright-turquoise': '#06f3f9',
    'warning': '#ffb800',
    'navy-blue': '#0069D2',
    'silver-grey': '#b9bcbf',
    'green': '#10b241',
    'orange': '#de9040',
    'red': '#de4040',
    'offline-gray': '#636363',
};

export default colors;
