import { grApiInstance } from './utils';

export const USER = '/v1.0/gr_users';

export function userFetcher(...key) {
    const [user, params] = key;

    return grApiInstance.get(
        user, { params }
    );
}
