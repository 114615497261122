import { transparentize } from '@chakra-ui/theme-tools';

function initControl() {
    return {
        width: 'initial',
        height: 'initial',
        border: 'initial',
        _checked: {
            background: 'initial',
            borderColor: 'initial',
            _hover: {
                background: 'initial',
                borderColor: 'initial',
            }
        },
        _indeterminate: {
            background: 'initial',
            borderColor: 'initial',
            _hover: {
                background: 'initial',
                borderColor: 'initial',
            }
        },
        _focus: {
            boxShadow: 'initial',
        }
    };
}

const Checkbox = {
    variants: {
        goshare({ theme }) {
            return {
                control: {
                    ...initControl(),
                },
                label: {
                    ...theme.textStyles.regular,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: 'charcoal-grey',
                }
            };
        },
        'multi-select-checkbox'({ theme }) {
            return {
                container: {
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                    padding: '4px 8px 4px 16px',
                    backgroundColor: '#f9f9f9',
                    border: 'solid 1px #f5f5f5',
                    borderRadius: '5px',
                    '&.checked': {
                        backgroundColor: transparentize('deep-sky-blue', .05)(theme),
                        borderColor: transparentize('deep-sky-blue', 0)(theme),
                    },
                },
                control: {
                    ...initControl(),
                    _checked: {
                        color: 'deep-sky-blue',
                        background: 'initial',
                        borderColor: 'initial',
                        _hover: {
                            background: 'initial',
                            borderColor: 'initial',
                        }
                    }
                },
                label: {
                    ...theme.textStyles.regular,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: 'charcoal-grey',
                    whiteSpace: 'nowrap',
                    _checked: {
                        color: 'deep-sky-blue',
                    },
                }
            };
        }
    },
    defaultProps: {
        variant: 'goshare',
    },
};

export default Checkbox;
