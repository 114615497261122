import TTNormsProRegularTTF from '../../assets/fonts/TT-Norms-Pro-Regular.ttf';
import TTNormsProRegularWOFF from '../../assets/fonts/TT-Norms-Pro-Regular.woff';
import TTNormsProMediumWOFF from '../../assets/fonts/TT-Norms-Pro-Medium.woff';
import TTNormsProMediumTTF from '../../assets/fonts/TT-Norms-Pro-Medium.ttf';
import TTNormsProExtraBoldWOFF from '../../assets/fonts/TT-Norms-Pro-ExtraBold.woff';
import TTNormsProExtraBoldTTF from '../../assets/fonts/TT-Norms-Pro-ExtraBold.ttf';
import TTNormsProBoldWOFF from '../../assets/fonts/TT-Norms-Pro-Bold.woff';
import TTNormsProBoldTTF from '../../assets/fonts/TT-Norms-Pro-Bold.ttf';

const fontRegular = `
@font-face {
    font-family: "TTNormsPro-Regular";
    src: url("${ TTNormsProRegularWOFF }") format("woff"),
         url("${ TTNormsProRegularTTF }") format("truetype");
    font-weight: normal;
    font-style: normal
}
`;

const fontMedium = `
@font-face {
    font-family: "TTNormsPro-Medium";
    src: url("${ TTNormsProMediumWOFF }") format("woff"),
         url("${ TTNormsProMediumTTF }") format("truetype");
    font-weight: normal;
    font-style: normal
}
`;

const fontBold = `
@font-face {
    font-family: "TTNormsPro-Bold";
    src: url("${ TTNormsProBoldWOFF }") format("woff"),
         url("${ TTNormsProBoldTTF }") format("truetype");
    font-weight: normal;
    font-style: normal
}
`;

const fontExtraBold = `
@font-face {
    font-family: "TTNormsPro-ExtraBold";
    src: url("${ TTNormsProExtraBoldWOFF }") format("woff"),
         url("${ TTNormsProExtraBoldTTF }") format("truetype");
    font-weight: normal;
    font-style: normal
}
`;


const fontFamily = `
    ${ fontRegular }
    ${ fontMedium }
    ${ fontBold }
    ${ fontExtraBold }
`;

export default fontFamily;
