import requireAll from '../utils/requireAll';

const fetchLangs = () => require.context('./translations/', true, /^.*\.js$/);
const translations = requireAll(fetchLangs(), 'locale/translations');
const translationMap = {};
const localeMap = {};
const namespaces = [];
const formalLocaleName = locale => {
    const lang = locale.substr(0, 2);
    const region = locale.substr(2, 2);

    return {
        name: (region ? `${ lang }-${ region }` : lang),
        lang,
    };
};

Object.keys(translations).forEach(key => {
    const [locale, namespace] = key.split('$');
    const { name, lang } = formalLocaleName(locale);

    // bind translation map
    translationMap[name] = {
        ...translationMap[name],
        [namespace]: translations[key],
    };

    if (name !== lang) {
        translationMap[lang] = {
            ...translationMap[name],
        };
    }

    // bind locale map
    localeMap[name] = {
        locale: name,
        exact: true,
    };

    if (lang !== name) {
        localeMap[lang] = {
            locale: lang,
            exact: false,
        };
    }

    if (!namespaces.includes(namespace)) {
        namespaces.push(namespace);
    }
});

export const getLocales = () => Object.values(localeMap)
    .filter(({ exact }) => exact)
    .map(({ locale }) => locale);

export { namespaces };

export const getTranslations = () => {
    return translationMap;
};
