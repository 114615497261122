/**
 * Logged in account
 */
const ACCOUNT_ITEM_KEY = 'logged-in-user';
const account = {
    token: '',
    expires_at: null,
    profile: null,
    set: res => {
        localStorage.setItem(ACCOUNT_ITEM_KEY, JSON.stringify(res));
    },

    get: () => {
        return JSON.parse(localStorage.getItem(ACCOUNT_ITEM_KEY));
    },

    setID: (id_token) => {
        account.token = id_token;
    },

    getID: () => {
        return account.token;
    },

    setExpiresAt: res => {
        if (res) {
            const { expires_in } = res;
            const expires_at = Date.now() + (Math.min(expires_in, 1800) * 1000);
            return account.expires_at = expires_at;
        }
        return account.expires_at = null;
    },


    getExpiresAt: () => {
        return account.expires_at;
    },

    setProfile: (res) => {
        const profile = JSON.stringify(res);
        account.profile = profile;
    },

    getProfile: () => {
        return JSON.parse(account.profile);
    },

    exists: () => {
        return !!account.get();
    },

    remove: () => {
        localStorage.removeItem(ACCOUNT_ITEM_KEY);
    },

    diff: () => {
        const inMemoryProfile = account.getProfile();
        const localStorageProfile = account.get();

        if (!(inMemoryProfile && localStorageProfile)) {
            return false;
        }

        return inMemoryProfile.email !== localStorageProfile.email;
    },
};

export default account;
