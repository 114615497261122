import useFetcher from '../hooks/useFetcher';
import { USER, userFetcher } from '../fetchers/user';

const PROFILE_FETCH_INTERVAL = 3600 * 1000;

export default function useUserProfile() {
    const { data, mutate, isValidating } = useFetcher(
        [USER],
        userFetcher,
        {
            refreshInterval: PROFILE_FETCH_INTERVAL,
            dedupingInterval: PROFILE_FETCH_INTERVAL,
        }
    );
    return {
        userProfile: data?.data ?? {},
        mutateUserProfile: mutate,
        isProfileLoading: isValidating,
    };
}
