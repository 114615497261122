import { Text, Center, Spinner } from '@chakra-ui/react';

export default function LoadingPage() {
    return (
        <Center width="100vw" height="100vh">
            <Spinner as="span" marginRight="15px" />
            <Text>loading...</Text>
        </Center>
    );
}
