import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Checkbox as ChakraCheckbox } from '@chakra-ui/react';
import { CheckIcon as ChakraCheckIcon } from '@chakra-ui/icons';
import { ReactComponent as CheckboxCheckedSVG } from '../../assets/checkbox-checked.svg';
import { ReactComponent as CheckboxMixedSVG } from '../../assets/checkbox-mixed.svg';
import { ReactComponent as CheckboxUncheckSVG } from '../../assets/checkbox.svg';

const CheckboxIndeterminateSVG = styled(CheckboxMixedSVG)({
    transform: 'rotate(90deg)'
});

function CheckedIcon(props) {
    const { isIndeterminate, isChecked } = props;
    let Icon = CheckboxUncheckSVG;

    if (isChecked) {
        Icon = CheckboxCheckedSVG;
    }

    if (isIndeterminate) {
        Icon = CheckboxIndeterminateSVG;
    }

    return (
        <Icon />
    );
}

CheckedIcon.propTypes = {
    isIndeterminate: PropTypes.bool,
    isChecked: PropTypes.bool,
};

CheckedIcon.defaultProps = {
    isIndeterminate: false,
    isChecked: false,
};

function MultiSelectCheckedIcon(props) {
    const { isChecked } = props;

    if (isChecked) {
        return (
            <ChakraCheckIcon width="20px" height="20px" marginLeft="0.5rem" />
        );
    }

    return null;
}

MultiSelectCheckedIcon.propTypes = {
    isChecked: PropTypes.bool,
};

MultiSelectCheckedIcon.defaultProps = {
    isChecked: false,
};

export default function Checkbox(props) {
    const { variant, defaultChecked, isChecked, onChange, ...restProps } = props;
    let icon = <CheckedIcon />;

    if (variant === 'multi-select-checkbox') {
        icon = <MultiSelectCheckedIcon />;
    }

    const [containerClassName, setContainerClassName] = useState('');

    // defaultChecked only be used at first time render
    const defaultCheckedRef = useRef(defaultChecked);
    useEffect(() => {
        if (defaultCheckedRef.current) {
            setContainerClassName('checked');
        }
    }, []);

    useEffect(() => {
        if (isChecked) {
            setContainerClassName('checked');
        }
        else {
            setContainerClassName('');
        }
    }, [isChecked]);

    const handleChange = (e) => {
        onChange(e);

        if (e.target.checked) {
            setContainerClassName('checked');
        }
        else {
            setContainerClassName('');
        }
    };

    return (
        <ChakraCheckbox
            variant={ variant }
            className={ containerClassName }
            defaultChecked={ defaultChecked }
            isChecked={ isChecked }
            icon={ icon }
            spacing={ variant === 'multi-select-checkbox' ? '0px' : undefined }
            onChange={ handleChange }
            { ...restProps }
        />
    );
}

Checkbox.propTypes = {
    variant: PropTypes.string,
    defaultChecked: PropTypes.bool,
    isChecked: PropTypes.bool,
    onChange: PropTypes.func,
};

Checkbox.defaultProps = {
    variant: undefined,
    defaultChecked: undefined,
    isChecked: undefined,
    onChange: () => {},
};
