import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverFooter,
    useDisclosure,
    Button,
    Box,
    Stack,
} from '@chakra-ui/react';
import isEqual from 'date-fns/isEqual';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import format from 'date-fns/format';
import subMilliseconds from 'date-fns/subMilliseconds';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import DatePicker from '../../DatePicker/DatePicker';
import SelectableButton from '../SelectableButton';
import { isEmpty } from '../../../utils/assertion';
import { DATE_TIME_FORMAT } from '../../../constants/common';

function DateTimeRangeSelect(props) {
    const { title, selected: selectedProps, onApply, limitDays } = props;
    const { isOpen, onClose, onToggle } = useDisclosure();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();
    const applied = !isEmpty(selectedProps);

    const filterStartTime = (time) => {
        if (!endDate) {
            return true;
        }

        return isEqual(time, endDate) || isBefore(time, endDate);
    };

    const filterEndTime = (time) => {
        if (!startDate) {
            return true;
        }

        return isEqual(time, startDate) || isAfter(time, startDate);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleMinDate = useCallback(
        () => {
            const min = limitDays ? subDays(endDate, limitDays - 1 ) : undefined;
            setMinDate(min);
        },
        [setMinDate, limitDays, endDate],
    );

    const handleMaxDate = useCallback(
        () => {
            const max = limitDays ? addDays(startDate, limitDays) : undefined;
            setMaxDate(subMilliseconds(max, 1));
        },
        [setMaxDate, limitDays, startDate],
    );

    const handleApply = () => {
        onApply({
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        });
        onClose();
    };

    const handleReset = (e) => {
        e.stopPropagation();
        setStartDate(undefined);
        setEndDate(undefined);
        setMinDate(undefined);
        setMaxDate(undefined);
        onApply(undefined);
        onClose();
    };

    const disabledApply = !startDate || !endDate || isAfter(startDate, endDate);
    const disabledClear = !startDate && !endDate;

    let tipText = '';

    if (applied) {
        const { startDate: startDateProps, endDate: endDateProps } = selectedProps;

        function formatter(date) {
            return format(new Date(date), DATE_TIME_FORMAT);
        }

        tipText = `${ formatter(startDateProps) } - ${ formatter(endDateProps) }`;
    }

    useEffect(() => {
        if (isOpen && selectedProps) {
            const { startDate: startDateProps, endDate: endDateProps } = selectedProps;
            setStartDate(new Date(startDateProps));
            setEndDate(new Date(endDateProps));
        }
    }, [selectedProps, isOpen]);

    useEffect(() => {
        handleMinDate();
    }, [endDate, handleMinDate]);

    useEffect(() => {
        handleMaxDate();
    }, [startDate, handleMaxDate]);

    return (
        <Box>
            <Popover
                variant="datetime-range-select"
                placement="bottom-start"
                isOpen={ isOpen }
                onClose={ onClose }
                lazyBehavior="keepMounted"
                isLazy
            >
                <PopoverTrigger>
                    <SelectableButton
                        onClick={ onToggle }
                        isOpen={ isOpen }
                        showTooltip={ applied }
                        tooltipLabel={ tipText }
                        badgeNumber={ 1 }
                    >
                        { title }
                    </SelectableButton>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverBody>
                        <Stack spacing="8px" direction={ ['column', 'column', 'row'] }>
                            <DatePicker
                                title="開始時間"
                                selected={ startDate }
                                onChange={ handleStartDateChange }
                                selectsStart
                                showTimeSelect
                                inline
                                disabledKeyboardNavigation
                                timeIntervals={ 30 }
                                startDate={ startDate }
                                endDate={ endDate }
                                maxDate={ endDate }
                                minDate={ minDate }
                                filterTime={ filterStartTime }
                            />
                            <DatePicker
                                title="結束時間"
                                selected={ endDate }
                                onChange={ handleEndDateChange }
                                selectsEnd
                                showTimeSelect
                                inline
                                disabledKeyboardNavigation
                                timeIntervals={ 30 }
                                startDate={ startDate }
                                endDate={ endDate }
                                minDate={ startDate }
                                maxDate={ maxDate }
                                filterTime={ filterEndTime }
                            />
                        </Stack>
                    </PopoverBody>
                    <PopoverFooter>
                        <Button
                            variant="link-cancel"
                            onClick={ handleReset }
                            disabled={ disabledClear }
                        >
                            清除
                        </Button>
                        <Button
                            variant="link-primary"
                            onClick={ handleApply }
                            disabled={ disabledApply }
                        >
                            套用
                        </Button>
                    </PopoverFooter>
                </PopoverContent>
            </Popover>
        </Box>
    );
}

DateTimeRangeSelect.propTypes = {
    title: PropTypes.string.isRequired,
    selected: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
    }),
    onApply: PropTypes.func,
    limitDays: PropTypes.number,
};

DateTimeRangeSelect.defaultProps = {
    selected: undefined,
    onApply: () => {},
    limitDays: undefined,
};

export default DateTimeRangeSelect;
